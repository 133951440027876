import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import refferal from '../../assets/images/PaymentSuccess.png'
import { CardActionArea } from '@mui/material';

export default function OfferCard({ offer }) {
    return (
        <Card sx={{ maxWidth: 1000, margin: '2vmax 0' }} >
            <CardActionArea>
                <CardMedia
                    component="img"
                    height="400"
                    image={offer.image}
                    alt="green iguana"
                    style={{ objectFit: 'fill' }} // Ensure the image covers the entire area without distortion
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {offer.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {/* Lizards are a widespread group of squamate reptiles, with over 6,000
                        species, ranging across all continents except Antarctica */}
                        {offer.description}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
