import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    admin: null,
    loading: false
}

const offerSlice = createSlice({
    name: "offers",
    initialState: initialState,
    reducers: {
        setAdmin: (state, action) => {
            state.admin = action.payload
        },
        logoutAdmin: (state, action) => {
            state.admin = null;
            state.loading = false;
            // localStorage.removeItem('adminToken')
        },
        createOfferRequest: (state) => {
            state.loading = true;
        },
        createOfferSuccess: (state) => {
            state.loading = false;
        },
        createOfferFailure: (state) => {
            state.loading = false;
        }
    }
}
)

export const { setAdmin, logoutAdmin, createOfferRequest, createOfferSuccess, createOfferFailure } = offerSlice.actions;

export default offerSlice.reducer