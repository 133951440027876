import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux"
import { Navigate, useLocation } from "react-router-dom"
import Loader from '../components/Loader/Loader';

const ProtectedRoute = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null) // Initial state is null to indicate loading
    const user = useSelector((state) => state.user);
    let location = useLocation();
    useEffect(() => {
        const token = localStorage.getItem("token")
        if (token) {
            setIsAuthenticated(true)
        } else {
            setIsAuthenticated(false)
        }
    }, [])

    if (isAuthenticated === null) {
        // Render a loading state while authentication status is being determined
        return <Loader />
    }

    if (!isAuthenticated) {
        console.log('here')
        return <Navigate to="/login" state={{ from: location }} replace />
    }
    return children

};

export default ProtectedRoute;
