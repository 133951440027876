import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CompanyLogo from '../../components/CompanyLogo/CompanyLogo'
import PlaceHolder from '../../assets/images/PlaceHolder.jpg'
import './AdminOffers.css'
import OffersTable from '../../components/OfferTable/OfferTable'
import { useForm } from 'react-hook-form'
import { createOffer } from '../../store/actions/offers.action'
import Loader from '../../components/Loader/Loader'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { logoutAdmin } from '../../store/reducers/offers.reducers'

const AdminOffers = () => {

    const [imagePreview, setImagePreview] = useState(null);
    const [file, setFile] = useState(null);
    const [isOfferTab, setIsOfferTab] = useState(true);
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loading = useSelector((state) => state.offer.loading)

    const handleImageChange = (e) => {
        console.log('here')
        const file = e.target.files[0];
        if (file) {
            setFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const onSubmit = (data) => {
        console.log(data);
        const formdata = new FormData();
        formdata.append('title', data.title)
        formdata.append('image', data.image[0])
        formdata.append('description', data.description)
        formdata.append('affilate_link', data.affilateLink)
        dispatch(createOffer(formdata))
        reset()
        setImagePreview(null)
    };

    const Logout = () => {
        dispatch(logoutAdmin);
        localStorage.removeItem('adminToken')
        navigate('/login')
    }


    return (
        <div >
            {loading && <Loader />}
            <nav class="navbar navbar-light bg-light" style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', paddingLeft: '2vmax' }}  >
                <a class="navbar-brand ms-2" >
                    <CompanyLogo />
                </a>
                <div class="form-inline">
                    <button className="voilet-btn" onClick={Logout}>Logout</button>
                </div>
            </nav>

            <div className='offers-container' style={{ padding: '2vmax' }} >

                <div className='d-flex justify-content-between mb-3 col-md-12'>
                    <h3>Offer Module</h3>
                    <button onClick={() => setIsOfferTab(!isOfferTab)} className='voilet-btn' type='button' style={{ paddingLeft: '40px', paddingRight: '40px' }} >
                        {
                            isOfferTab ? 'See Offers' : 'Create Offer'
                        }
                    </button>
                </div>
                {
                    isOfferTab ?
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="col-md-12 row">
                                <div className="col-md-6">
                                    <div className="col-md-12 mb-4">
                                        <label htmlFor="title" className="form-label">Title* </label>
                                        <input
                                            type="text"
                                            name="title"
                                            id="title"
                                            placeholder="Enter the title"
                                            className="form-control"
                                            {...register("title", { required: "Title is required" })}
                                        />
                                        {errors.title && <p className="text-danger">{errors.title.message}</p>}
                                    </div>
                                    {/* <div className="col-md-12 mb-3">
                                        <label htmlFor="affilateLink" className="form-label">Affilate Link </label>
                                        <input
                                            type="text"
                                            name="affilateLink"
                                            id="affilateLink"
                                            className="form-control"
                                            {...register("affilateLink")}
                                        />
                                    </div> */}
                                    <div className="col-md-12 mt-4 mb-4">
                                        <label htmlFor="image" className="form-label">Select Image* </label>
                                        <input
                                            type="file"
                                            name="image"
                                            id="image"
                                            className="form-control"
                                            accept="image/png, image/jpeg, image/jpg, image/gif"
                                            // onChange={handleImageChange}
                                            {...register("image", { required: "Image is required", onChange: (e) => handleImageChange(e) })}
                                        />
                                        {errors.image && <p className="text-danger">{errors.image.message}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className='col-md-12'>
                                        {imagePreview ? (
                                            <img src={imagePreview} alt="Preview" style={{ maxHeight: '300px', maxWidth: '600px' }} />
                                        ) : (
                                            <img src={PlaceHolder} alt="Placeholder" style={{ maxHeight: '300px', maxWidth: '600px' }} />
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <label htmlFor="description" className="form-label">Description*</label>
                                    <textarea
                                        name="description"
                                        id="description"
                                        className='form-control'
                                        placeholder="Enter the description"
                                        rows={7}
                                        {...register("description", { required: "Description is required" })}
                                    ></textarea>

                                    {/* <ReactQuill
                                        theme="snow"
                                        value={register("description").value || ""}
                                        onChange={(html) => register("description", { value: html })}
                                    /> */}
                                    {errors.description && <p className="text-danger">{errors.description.message}</p>}
                                </div>
                                <div className='mt-3'>
                                    <button className="voilet-btn" style={{ paddingLeft: '40px', paddingRight: '40px' }}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                        :
                        <div className='col-md-12'>
                            <OffersTable />
                        </div>
                }
            </div>
        </div>
    )
}

export default AdminOffers