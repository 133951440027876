import React from 'react';
import './NotFound.css'

const NotFound = () => {
  return (
    <div className='not-found'>
      <h1>404 Not Found</h1>
      <p>The page you're looking for does not exist or you are not authenticated.</p>
    </div>
  );
};

export default NotFound;