import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import { formateDateToAbbreviatedMonthDate } from '../../Utils/pipes';

// Styled components
const StyledTableContainer = styled(TableContainer)({
    borderRadius: 10,
    margin: '20px 0', // Adjust the margin to ensure consistent spacing

    overflow: 'hidden',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    border: '1px solid #ddd', // Add border to the table container
});

const StyledTableHead = styled(TableHead)({
    backgroundColor: '#f5f5f5',
});

const StyledTableCell = styled(TableCell)({
    fontSize: '16px',
    color: '#333',
    borderBottom: 'none', // Remove bottom border from table cells
    // padding: '10px 16px', // Add some padding for better spacing
});

const StatusTableCell = styled(StyledTableCell)({
    fontWeight: 'bold',
    textTransform: 'capitalize', // Capitalize status for consistency
});

const StyledTableRow = styled(TableRow)({
    '&:nth-of-type(odd)': {
        backgroundColor: '#f9f9f9', // Light gray for odd rows
    },
    '&:nth-of-type(even)': {
        backgroundColor: '#fff', // White for even rows
    },
    '&:last-child td, &:last-child th': {
        border: 0, // Remove border from the last row
    }
});

// Sample data
// const data = [
//     { email: 'janedoe@gmail.com', updated: '09, jun24 6:50PM', status: 'Signed up' },
//     { email: 'janedoe@gmail.com', updated: '09, jun24 6:50PM', status: 'Subscribed monthly' },
//     { email: 'janedoe@gmail.com', updated: '09, jun24 6:50PM', status: 'Subscribed Annually' },
// ];

// Component definition
const ReferralTable = ({ headings, data, key1, key2 }) => {

    const isMobileScreen = useMediaQuery('(max-width:600px)')

    return (
        <StyledTableContainer component={Paper} sx={{ overflowX: 'auto', width: isMobileScreen ? '90vw' : '100%', background: "#FCFCFC", maxWidth: '1200px', minHeight: '100px' }}>
            <Table>
                <StyledTableHead>
                    <StyledTableRow style={{ border: "1px solid #ddd" }} >
                        {
                            headings.map((item, index) => (
                                index === headings.length - 1 ?
                                    <StatusTableCell>{item}</StatusTableCell> :
                                    <StyledTableCell>{item}</StyledTableCell>
                            )
                            )
                        }
                        {/* <StyledTableCell>Recipient email</StyledTableCell>
                        <StyledTableCell>Updated</StyledTableCell>
                        <StatusTableCell>Points</StatusTableCell> */}
                    </StyledTableRow>
                </StyledTableHead>
                <TableBody>
                    {data.map((row, index) => (
                        <StyledTableRow key={index}>
                            <StyledTableCell sx={{ width: '40%' }} >{key1 === 'redemptionType' ? row[`${key1}`] : row.newUser.email}</StyledTableCell>
                            <StyledTableCell sx={{ width: '30%' }}>{formateDateToAbbreviatedMonthDate(row.updatedAt)}</StyledTableCell>
                            <StatusTableCell sx={{ width: '30%' }}>{row[`${key2}`]}</StatusTableCell>
                        </StyledTableRow>
                    ))}
                    {/* {React.Children.map(children, (child, index) => (
                        <StyledTableRow key={index}>
                            {React.cloneElement(child, {
                                // Add any additional props needed
                            })}
                        </StyledTableRow>
                    ))} */}
                </TableBody>
            </Table>
        </StyledTableContainer>
    );
};

export default ReferralTable;
