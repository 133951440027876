import React from 'react'
import ReferralPoint from '../../../../components/ReferralPoint/ReferralPoint'
import { bonusDetails } from '../../../../constant/app-constants'

const ReferralDetails = () => {
    return (
        <div>
            <h3 style={{ margin: 0, padding: 0, color: 'black', marginBottom: '1.5vmax' }} >
                Referral Program
            </h3>
            <div style={{ width: '800px' }}>
                <h5>Earn 500 Skypoints for Every Referral</h5>
                <p>Get 500 Skypoints for each successful referral and signup.
                        Your friend will also receive 500 Skypoints. If your friend purchases any storage subscription plan,
                        then you both also receive more Skypoints every month for an active subscription.</p>
            </div>
            <div style={{ boxShadow: '0px 2px 10px 0px #0000001A', border: '2px solid #95929233', borderRadius: '10px', padding: "1vmax", marginTop: '1.5vmax' }} >
                {
                    bonusDetails.map((item) => (
                        <ReferralPoint description={item.description} heading={item.title} />
                    ))
                }
            </div>
        </div>
    )
}

export default ReferralDetails