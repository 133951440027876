import React, { useEffect, useState } from 'react'
import bell from '../../../assets/images/Bell.png'
import './UpgradePlan.css'
import { useMediaQuery } from '@mui/material';
import Proplan from '../../../assets/images/Pro Plan.png'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import CancellationReasonDialog from '../../../components/CancelSubscription/CancelSubscription';
import { useDispatch, useSelector } from 'react-redux';
import { getUpgradePlanDetails } from '../../../store/actions/subscription.actions';
import { formateDateToAbbreviatedMonthDate } from '../../../Utils/pipes';
import Loader from '../../../components/Loader/Loader';
import { useNavigate } from 'react-router-dom';
import { SelectPlanAction } from '../../../store/reducers/user.reducers';
import Checkbox from '@mui/material/Checkbox';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


const UpgradePlan = () => {

    const isMobileScreen = useMediaQuery('(max-width:600px)');
    const isDesktopScreen = useMediaQuery('(min-width:1024px)');

    const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [isSecondaryDialogOpen, setSecondaryDialogOpen] = useState(false);
    const [isConfirmSubscriptionDialog, setIsConfirmSubscriptionDialog] = useState(false)
    const [planType, setPlanType] = useState('MONTH')
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const subscriptionInfo = useSelector((state) => state.subscription.upgradePlanDetails)
    const loading = useSelector((state) => state.subscription.loading)

    useEffect(() => {
        dispatch(getUpgradePlanDetails(planType))
        if (subscriptionInfo && subscriptionInfo.currentPlanDetails.planDetail.planName === "Master Plan") {
            setPlanType("YEAR")
        }
    }, [planType, dispatch])

    const handleCancelClick = () => {
        setConfirmDialogOpen(true);
    };

    const handleConfirm = () => {
        setConfirmDialogOpen(false);
        setSecondaryDialogOpen(true);
    };

    const handleCloseSecondaryDialog = () => {
        setSecondaryDialogOpen(false);
    };

    const onOptionChange = e => {
        setPlanType(e.target.value)
    }

    const handleConfirmUpgrade = (plan) => {
        dispatch(SelectPlanAction(plan))
        setIsConfirmSubscriptionDialog(false)
        setTimeout(() => {
            navigate('/payment')
        }, 300)
    }

    const handleConfirmClose = () => {
        setIsConfirmSubscriptionDialog(true)
    }



    return (
        <div className='upgrade-plan' >
            {loading && <Loader />}
            {
                subscriptionInfo &&
                <div className='text-center' style={{ background: '#5D5D5DCC', padding: "0.5vmax", color: "white", borderTopLeftRadius: "20px", borderTopRightRadius: '20px' }}>
                    <img src={bell} alt="" style={{ marginRight: '10px' }} />
                    Your subscription will end on  {formateDateToAbbreviatedMonthDate(subscriptionInfo.currentPlanDetails.subscriptionDetail.currentPeriodEnd)}
                </div>
            }
            <div style={{ padding: "2vmax 3vmax" }} >
                <h1 className='mb-4'>Manage <span style={{ color: '#7F88E7' }}>Subscription</span> plan</h1>
                {subscriptionInfo && (subscriptionInfo.currentPlanDetails.planDetail.planType !== 'LifeTime' ?
                    <div className='current-plan-card' style={{ width: isMobileScreen ? '100%' : '450px' }}>
                        <button className='btn' style={{ border: "1px solid #747DD7", borderRadius: '30px', padding: "3px 10px", background: 'white' }}>
                            Current Plan : <strong>{subscriptionInfo.currentPlanDetails.planDetail.planName}</strong>
                        </button>
                        <div style={{ padding: isDesktopScreen ? "2vmax 3.5vmax" : "3vmax 0" }} >
                            <h3>Billing Summary</h3>
                            <div className='d-flex align-items-center'>
                                <h3 style={{ color: '#7F88E7' }} >
                                    {subscriptionInfo.currentPlanDetails.planDetail.planName}
                                </h3>
                                <p className='m-2' style={{ color: "#959292" }} >
                                    {subscriptionInfo.currentPlanDetails.planDetail.planSpace}
                                </p>
                            </div>
                            <div className='d-flex align-items-center'>
                                <h5>
                                    ${subscriptionInfo.currentPlanDetails.planDetail.planAmount.toFixed(2)} USD
                                </h5>
                                <p style={{ color: "#959292", marginLeft: '5px' }}>
                                    {subscriptionInfo.currentPlanDetails.planDetail.planType === "MONTH" ? "Per month" : "Per Year"}
                                </p>
                            </div>
                            <div className='d-flex align-items-center'>
                                Billing cycle: {subscriptionInfo.currentPlanDetails.planDetail.planType === "MONTH" ? "Billed monthly" : "Billed yearly"}
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1vmax' }}>
                                <button className='cancel-subscription' onClick={handleCancelClick}>
                                    Cancel Subscription
                                </button>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='current-plan-card' style={{ width: isMobileScreen ? '100%' : '450px' }}>
                        <button className='btn' style={{ border: "1px solid #747DD7", borderRadius: '30px', padding: "3px 10px", background: 'white' }}>
                            Current Plan : <strong>{subscriptionInfo.currentPlanDetails.planDetail.planName}</strong>
                        </button>
                        <div style={{ padding: isDesktopScreen ? "2vmax 3.5vmax" : "3vmax 0" }} >
                            <div className='d-flex align-items-center'>
                                <h3 style={{ color: '#7F88E7' }} >
                                    {subscriptionInfo.currentPlanDetails.planDetail.planName}
                                </h3>
                                <p className='m-2' style={{ color: "#959292" }} >
                                    {subscriptionInfo.currentPlanDetails.planDetail.planSpace}
                                </p>
                            </div>
                            <div className='d-flex align-items-center'>
                                <h5>
                                    ${subscriptionInfo.currentPlanDetails.planDetail.planAmount.toFixed(2)} USD
                                </h5>
                                <p style={{ color: "#959292", marginLeft: '5px' }}>
                                    {subscriptionInfo.currentPlanDetails.planDetail.planType === "MONTH" ? "Per month" : "Per Year"}
                                </p>
                            </div>
                            {/* <div className='d-flex align-items-center'>
                                Billing cycle: {subscriptionInfo.currentPlanDetails.planDetail.planType === "MONTH" ? "Billed monthly" : "Billed yearly"}
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1vmax' }}>
                                <button className='cancel-subscription' onClick={handleCancelClick}>
                                    Cancel Subscription
                                </button>
                            </div> */}
                        </div>
                    </div>
                )
                }
                {subscriptionInfo &&
                    <div className='d-flex flex-wrap mb-3'>
                        <div className='upgrade-plan-card' style={{ width: isMobileScreen ? '100%' : '450px' }}>
                            <button className='btn' style={{ border: "1px solid #747DD7", borderRadius: '30px', padding: "3px 10px", background: 'white' }}>
                                Upgrade to : <strong>{subscriptionInfo.nextPlanDetail.planName} </strong>
                            </button>

                            <div style={{ padding: isDesktopScreen ? "2vmax 3.5vmax" : "3vmax 0" }} >
                                <div className='d-flex align-items-center'>
                                    <div  >
                                        <img src={Proplan} alt="" style={{ marginRight: '10px' }} />
                                    </div>
                                    <h3 >
                                        {subscriptionInfo.nextPlanDetail.planName}
                                    </h3>
                                    <p className='m-3' style={{ color: "#959292" }} >
                                        {subscriptionInfo.nextPlanDetail.planSpace} space
                                    </p>
                                </div>
                                <div className='d-flex p-2 align-items-center'>
                                    <h5>
                                        ${subscriptionInfo.nextPlanDetail.planAmount.toFixed(2)} USD
                                    </h5>
                                    <p style={{ color: "#959292", marginLeft: '5px' }}>
                                        {subscriptionInfo.nextPlanDetail.planType === "MONTH" ? "Per month" : "Per Year"}
                                    </p>
                                </div>
                                <div className='ms-1 mb-4'>
                                    {subscriptionInfo.currentPlanDetails.planDetail.planName !== "Master Plan" &&
                                        <>
                                            <input
                                                className='radio-btn'
                                                type="radio"
                                                id="contactChoice1"
                                                name="contact"
                                                value="MONTH"
                                                checked={planType === "MONTH"}
                                                onChange={onOptionChange}
                                            />
                                            <label className='radio-btn-label' for="contactChoice1"   >Billed Monthly</label>
                                        </>
                                    }

                                    <input
                                        className='radio-btn'
                                        type="radio"
                                        id="contactChoice2"
                                        name="contact"
                                        value="YEAR"
                                        checked={planType === "YEAR"}
                                        onChange={onOptionChange}
                                    />
                                    <label className='radio-btn-label' for="contactChoice2">Billed Yearly</label>
                                    {/* </div> */}
                                </div>

                            </div>
                        </div>
                        <button
                            style={{ border: 'none', background: 'transparent', color: "#747DD7", fontWeight: 600, margin: "2vmax" }}
                            onClick={() => navigate('/subscripiton-plan?isUpgrade=true')}
                        >
                            + SEE MORE
                        </button>
                    </div>
                }
                {/* {
                    subscriptionInfo && subscriptionInfo.currentPlanDetails.planDetail.planName !== "Master Plan" &&
                } */}
                <button
                    style={{ width: '150px', marginTop: '35px', marginLeft: '35px', marginBottom: '35px' }} className='voilet-btn'
                    onClick={handleConfirmClose}
                >Confirm Upgrade</button>
                <p style={{ marginBottom: '0' }}>Enjoy complete benefits by subscribing for exclusive content and special features.</p>
                <div >
                    {/* <input class="form-check-input" type="checkbox" value="true" id="defaultCheck1" /> */}
                    {/* <Checkbox {...label} disabled checked size="small" /> */}
                    <span class="form-check-label" for="defaultCheck1">
                        Downgrade automatically at the end of the current billing cycle (i.e., 29 september 2024))
                    </span>
                </div>
            </div>

            <Dialog open={isConfirmDialogOpen}>
                {/* <DialogTitle>Confirm Cancellation</DialogTitle> */}
                <DialogContent>
                    <h3 style={{ textAlign: 'center' }}>
                        Are You sure you want Cancel
                        your Subscription plan?
                    </h3>
                    <p style={{ textAlign: 'center', color: "#00000080" }}>
                        Your subscription will end immediately.
                    </p>
                    {/* <DialogContentText>Are you sure you want to cancel your subscription?</DialogContentText> */}
                </DialogContent>
                <div style={{ marginBottom: "1vmax", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <button onClick={() => setConfirmDialogOpen(false)} className="voilet-btn" style={{ paddingLeft: '20px', paddingRight: '20px' }} >
                        Not Now
                    </button>
                    <button onClick={handleConfirm} className="voilet-btn ms-3" style={{ paddingLeft: '20px', paddingRight: '20px' }} >
                        Yes, Cancel it
                    </button>
                </div>
            </Dialog>


            <Dialog open={isConfirmSubscriptionDialog}>
                {/* <DialogTitle>Confirm Cancellation</DialogTitle> */}
                <DialogContent>
                    <h3 style={{ textAlign: 'center' }}>
                        Are you sure?
                    </h3>
                    <p style={{ textAlign: 'center', color: "#00000080", maxWidth: '400px' }}>
                        Are you sure you want to change plans now?
                        Note: This will take effect immediately                    </p>
                    {/* <DialogContentText>Are you sure you want to cancel your subscription?</DialogContentText> */}
                </DialogContent>
                <div style={{ marginBottom: "1vmax", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <button onClick={() => setIsConfirmSubscriptionDialog(false)} className="voilet-btn" style={{ paddingLeft: '20px', paddingRight: '20px' }} >
                        Cancel
                    </button>
                    <button onClick={() => handleConfirmUpgrade(subscriptionInfo.nextPlanDetail)} className="voilet-btn ms-3" style={{ paddingLeft: '20px', paddingRight: '20px' }} >
                        Proceed
                    </button>
                </div>
            </Dialog>

            <CancellationReasonDialog
                open={isSecondaryDialogOpen}
                onClose={handleCloseSecondaryDialog}
            />

        </div>

    )
}

export default UpgradePlan