import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./store/reducers/user.reducers";
import uploadReducer from "./store/reducers/upload.reducers";
import subscriptionReducer from './store/reducers/subscription.reducers'
import offerReducer from './store/reducers/offers.reducers'

const store = configureStore({
    reducer: {
        user: userReducer,
        upload: uploadReducer,
        subscription: subscriptionReducer,
        offer: offerReducer,
    }
});

export default store;