import React, { useEffect } from 'react';
import CheckoutForm from '../Checkout/Checkout';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { PAYPAL_CLIENT_ID } from '../../constant/app-constants'

const PaymentMethod = () => {

    const userState = useSelector((state) => state.user)
    const navigate = useNavigate()
    useEffect(() => {
        if (!userState.selectedPlan) navigate('/subscripiton-plan')
    }, [])

    return (
        < PayPalScriptProvider options={{
            // "client-id": "AVtiILuVYNNAQotHQBpCnMUHBFtBh2FHl_Axy9ZwDbpY-rhpc49PKxywubMtfDhHQt3W4LXyjinpZw7d",
            "clientId": PAYPAL_CLIENT_ID,
            vault: true,
            currency: "USD",
            intent: "subscription"
        }}
        >
            {userState.selectedPlan && <CheckoutForm selectedPlan={userState.selectedPlan} />}
        </PayPalScriptProvider >
    );
}

export default PaymentMethod;
