import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { API_URL } from '../../../constant/app-constants';
import OfferCard from '../../../components/OfferCard/OfferCard';
import './Offers.css';

const OffersList = () => {
    const [offers, setOffers] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const observer = useRef();

    const lastOfferElementRef = useCallback(node => {
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPage(prevPage => prevPage + 1);
            }
        });
        if (node) observer.current.observe(node);
    }, [hasMore]);

    const fetchOffers = useCallback(async () => {
        const token = localStorage.getItem('token');
        try {
            const res = await axios.get(`${API_URL}/api/v1/offer`, {
                params: { page, limit: 10, search: searchQuery },
                headers: { Authorization: 'Bearer ' + token }
            });
            setOffers(prevOffers => (page === 1 ? res.data.offers : [...prevOffers, ...res.data.offers]));
            setHasMore(res.data.offers.length > 0);
        } catch (error) {
            console.error('Error fetching offers:', error);
        }
    }, [page, searchQuery]);

    useEffect(() => {
        fetchOffers();
    }, [fetchOffers]);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setPage(1);
        setOffers([]);
    };

    return (
        <>
            <div className='d-flex align-items-center' style={{ padding: '0 2vmax ', maxWidth: 1000 }}>
                <h3>Search for offers:</h3>
                <div className='col-md-5 ms-3'>
                    <input
                        type="search"
                        className="form-control"
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                </div>
            </div>
            <div className='offers' style={{ padding: '2vmax', overflowY: 'auto', height: '75vh' }}>
                {offers.map((offer, index) => {
                    if (offers.length === index + 1) {
                        return (
                            <div ref={lastOfferElementRef} key={offer.id} style={{ background: '' }}>
                                <OfferCard offer={offer} />
                            </div>
                        );
                    } else {
                        return (
                            <div key={offer.id}>
                                <OfferCard offer={offer} />
                            </div>
                        );
                    }
                })}
                {offers.length > 0
                    ?
                    <button
                        type='submit' className="voilet-btn mb-3 text-center" style={{ paddingLeft: '50px', paddingRight: '50px' }}
                    >{hasMore ? 'Loading more offers...' : 'No more offers'}</button>
                    :
                    <button
                        type='submit' className="voilet-btn mb-3 text-center" style={{ paddingLeft: '50px', paddingRight: '50px' }}
                    >No offers found</button>
                }
                {/* <button type='submit' className="voilet-btn mb-3" style={{ paddingLeft: '20px', paddingRight: '20px' }} >
                            Generate Gift Card
                        </button> */}
            </div>
        </>
    );
};

export default OffersList;
