import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    path: '',
    currentfilesDir: [],
    isDownloadNotificationOpen: false,
    downloadingProgress: [],
    storageSpace: null
}

export const uploadSlice = createSlice({
    name: 'upload',
    initialState: initialState,
    reducers: {

        GetFilesAndDirRequest: (state, action) => {
            state.loading = true;
        },
        GetFilesAndDirSuccess: (state, action) => {
            state.currentfilesDir = action.payload
            state.loading = false;
            // state.path = action.payload;
        },
        GetFilesAndDirFailure: (state, action) => {
            state.loading = false;
            state.currentfilesDir = [];
        },


        RemoveFilesAndDirRequest: (state, action) => {
            state.loading = true;
        },
        RemoveFilesAndDirSuccess: (state, action) => {
            state.currentfilesDir = action.payload
            state.loading = false;
            // state.path = action.payload;
        },
        RemoveFilesAndDirFailure: (state, action) => {
            state.loading = false;
            // state.currentfilesDir = [];
        },


        CreateDirRequest: (state, action) => {
            state.loading = true;
        },
        CreateDirSuccess: (state, action) => {
            state.currentfilesDir = action.payload
            state.loading = false;
            // state.path = action.payload;
        },
        CreateDirFailure: (state, action) => {
            state.loading = false;
            // state.currentfilesDir = [];
        },


        DownloadFilesAndDirRequest: (state, action) => {
            state.loading = true;
        },
        DownloadFilesAndDirSuccess: (state, action) => {
            state.loading = false;
            // state.path = action.payload;
        },
        DownloadFilesAndDirFailure: (state, action) => {
            state.loading = false;
            // state.currentfilesDir = [];
        },

        setDownloadNotification: (state, action) => {
            state.isDownloadNotificationOpen = action.payload;
        },

        removeDownloadNotification: (state, action) => {
            const updateProgress = state.downloadingProgress.filter((item) => item.id !== action.payload)
            if (updateProgress.length === 0) {
                state.isDownloadNotificationOpen = false;
            }
            state.downloadingProgress = updateProgress;
        },

        intiateDownloading: (state, action) => {
            const newDownload = {
                id: action.payload,
                progress: 0
            }
            state.downloadingProgress = [...state.downloadingProgress, newDownload];
        },

        setProgress: (state, action) => {
            const updateProgress = state.downloadingProgress.map((item) => {
                return action.payload.index === item.id ? { ...item, progress: action.payload.progress } : item
            })
            state.downloadingProgress = updateProgress
        },

        StorageSpaceSuccess: (state, action) => {
            // state.loading = false;
            // state.path = action.payload;
            state.storageSpace = action.payload
        },

    }
})


export const {
    GetFilesAndDirRequest, GetFilesAndDirFailure, GetFilesAndDirSuccess,
    RemoveFilesAndDirRequest, RemoveFilesAndDirFailure, RemoveFilesAndDirSuccess,
    CreateDirRequest, CreateDirFailure, CreateDirSuccess,
    DownloadFilesAndDirRequest, DownloadFilesAndDirFailure, DownloadFilesAndDirSuccess,
    setDownloadNotification, setProgress, intiateDownloading, removeDownloadNotification, 
    StorageSpaceSuccess
} = uploadSlice.actions;

export default uploadSlice.reducer;