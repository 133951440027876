import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import SadChatBot from '../../assets/images/chatgpt robot sad.png'
import { useDispatch, useSelector } from 'react-redux';
import { ToggleCancelSuccessDailog, cancelUserSubscriptionRequest } from '../../store/reducers/subscription.reducers';
import { cancelUserSubscription } from '../../store/actions/subscription.actions';
import { useNavigate } from 'react-router-dom';

const CancellationReasonDialog = ({ open, onClose }) => {
    const [reason, setReason] = useState('');
    const isConfirmDialogOpen = useSelector((state) => state.subscription.cancelSuccessDailog);
    const navigate = useNavigate();
    const [cancellationReasons, setCancellationReasons] = useState([
        'No longer need storage',
        'Too expensive',
        'Unsatisfied with features',
        'Switching to another service',
        'Not sure why',
    ])
    const [selectedcancellationReason, setSelectedcancellationReason] = useState('')
    const dispatch = useDispatch()

    const handleReasonChange = (event) => {
        setSelectedcancellationReason(event.target.value);
    };

    const handleSubmit = () => {
        // Handle the submission logic here, e.g., send the reason to the server
        console.log('Cancellation reason:', reason);
        dispatch(cancelUserSubscription())
        onClose();
    };

    const handleConfirm = () => {
        navigate('/dashboard')
        dispatch(ToggleCancelSuccessDailog(false))
    };

    return (
        <>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <h3>We're sad to see you go!</h3>
                    <p>Before you cancel, please let us know why you're leaving:</p>
                    <div>
                        <img src={SadChatBot} alt="" />
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className='mb-2'>
                        {
                            cancellationReasons.map((item, index) => (
                                <button
                                    key={index}
                                    style={{ margin: '5px', padding: "3px 7px", background: 'transparent', color: item === selectedcancellationReason ? '#747DD7' : '#00000080', fontWeight: '600', border: item === selectedcancellationReason ? '1px solid #747DD7' : '0.75px solid #959292' }}
                                    onClick={() => setSelectedcancellationReason(item)}
                                >{item}</button>
                            ))
                        }
                    </div>
                    <div>
                        <label htmlFor="" className='mb-3'>We value your feedback. Please let us know how we can improve:</label>
                        <textarea
                            autoFocus
                            className='form-control'
                            margin="dense"
                            id="reason"
                            label="Reason for Cancellation"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={selectedcancellationReason}
                            onChange={handleReasonChange}
                        />
                    </div>
                    <div className='col-md-12 mt-3 d-flex justify-content-center'>
                        <button className='col-md-5 voilet-btn' color="primary"
                            onClick={onClose}
                        >
                            Nevermind
                        </button>
                        <button className='col-md-5 ms-3' onClick={handleSubmit} color="primary"
                            style={{ border: "1px solid #747DD7", borderRadius: '10px', padding: "3px 10px", background: 'white' }}
                        >
                            Cancel my Subscription
                        </button>
                    </div>
                </DialogContent>
                <DialogActions className='d-flex col-md-12 p-2'>

                </DialogActions>
            </Dialog>
            <Dialog open={isConfirmDialogOpen}>
                {/* <DialogTitle>Confirm Cancellation</DialogTitle> */}
                <DialogContent>
                    <h3 style={{ textAlign: 'center' }}>
                        Your Subscription has been
                        cancelled successfully.
                    </h3>
                    <p style={{ textAlign: 'center', color: "#00000080" }}>
                        You will still get to Enjoy all the features under the Free-Tier.                    </p>
                    {/* <DialogContentText>Are you sure you want to cancel your subscription?</DialogContentText> */}
                </DialogContent>
                <div style={{ marginBottom: "1vmax", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <button onClick={handleConfirm} className="voilet-btn" style={{ paddingLeft: '20px', paddingRight: '20px' }} >
                        Continue
                    </button>
                    {/* <button onClick={handleConfirm} className="voilet-btn ms-3" style={{ paddingLeft: '20px', paddingRight: '20px' }} >
                        Yes, Cancel it
                    </button> */}
                </div>
            </Dialog>
        </>

    );
};

export default CancellationReasonDialog;
