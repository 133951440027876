import React, { useState, useRef, useEffect } from 'react';
import loginImage from '../../assets/images/Drone_Image.png';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import './SignUp.css'
import { useDispatch, useSelector } from 'react-redux';
import { registerUser } from '../../store/actions/user.actions';
import { emailPattern, passwordPattern } from '../../constant/app-constants';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import eye from '../../assets/images/eye.png'
import hide from '../../assets/images/hide.png'

const SignUp = () => {
    const { register, handleSubmit, formState: { errors }, reset, watch } = useForm();
    const password = watch('password');
    const country = watch('country')
    const [selectedValue, setSelectedValue] = useState('');
    const [address, setAddress] = useState({ localAddress: "", latitude: "", longitude: "" })
    const dispatch = useDispatch();
    const history = useNavigate()
    const { loading } = useSelector((state) => state.user);
    const [showPassword, setShowPassword] = useState(false); // State to track whether password is shown or hidden
    const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State to track whether password is shown or hidden
    const [addressError, setAddressError] = useState("")

    const autoCompleteRef = useRef();
    const inputRef = useRef();
    const options = {
        // componentRestrictions: { country: "ng" },
        fields: ["address_components", "geometry", "icon", "name"],
        types: ["establishment"]
    };

    useEffect(() => {
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
            inputRef.current,
            options
        );

        autoCompleteRef.current.addListener("place_changed", handlePlaceChanged);
        // const inputElement = inputRef.current;
        // const handleInputChange = (event) => {
        //     console.log('here')
        //     const value = event.target.value.trim();
        //     if (value !== event.target.value) {
        //         setAddressError("No leading or trailing spaces allowed in Location");
        //     } else {
        //         setAddressError("");
        //     }
        // };

        // inputElement.addEventListener('input', handleInputChange);
        return () => {
            // Clean up event listener on unmount
            if (autoCompleteRef.current && autoCompleteRef.current.removeListener) {
                autoCompleteRef.current.removeListener("place_changed", handlePlaceChanged);
            }
        };

    }, []);

    const handlePlaceChanged = () => {
        if (!inputRef.current.value) {
            setAddressError("Location is required");
            return;
        } else if (inputRef.current.value.trim() !== inputRef.current.value) {
            setAddressError("No leading or trailing spaces allowed");
            return;
        } else {
            setAddressError("");
        }
        const place = autoCompleteRef.current.getPlace();
        const { geometry } = place;
        setAddress(prevAddress => {
            return { ...prevAddress, localAddress: inputRef.current.value }
        })
        if (geometry) {

            const latitude = geometry.location.lat();
            const longitude = geometry.location.lng();
            setAddress(prevAddress => {
                return { ...prevAddress, latitude: latitude, longitude: longitude }
            })
        }
        setAddressError("");
    };

    const noLeadingOrTrailingSpaces = (value) => {
        return value.trim() === value || "No leading or trailing spaces allowed";
    };

    const lettersAndNumbersOnly = (value) => {
        return /^[a-zA-Z0-9]+$/.test(value) || "Only letters and numbers are allowed";
    };

    const onSubmit = (data) => {
        if (!inputRef.current.value) {
            setAddressError("Location is required");
            return;
        } else if (inputRef.current.value.trim() !== inputRef.current.value) {
            setAddressError("No leading or trailing spaces allowed");
            return;
        } else {
            setAddressError("");
        }
        const { firstName, lastName, email, password, referral_code } = data;
        dispatch(registerUser(firstName, lastName, email, address, '113', password, history, referral_code));
        reset(); // Reset the form after successful submission
    };

    return (
        <div className='Login container-fluid'>
            {loading && <Loader />}
            <div className="row">
                <div className="col-md-5" style={{ margin: 0, padding: 0 }}>
                    <img className='image custom-image' src={loginImage} alt="" />
                </div>
                <div className="col-md-6">
                    <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className="heading mb-4">
                            <h1>Create an account</h1>
                        </div>
                        <div className="row col-md-9">
                            <div className="col-md-6 mt-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="First Name"
                                    autocomplete="off"
                                    {...register('firstName', {
                                        required: true,
                                        required: "First Name is required",
                                        validate: {
                                            noLeadingOrTrailingSpaces,
                                            lettersAndNumbersOnly
                                        }

                                    })}
                                />
                                {errors.firstName && <p className="text-danger">{errors.firstName.message}</p>}
                            </div>

                            <div className="col-md-6 mt-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Last Name"
                                    autocomplete="off"
                                    {...register('lastName', {
                                        required: true,
                                        required: "Last Name is required",
                                        validate: {
                                            noLeadingOrTrailingSpaces,
                                            lettersAndNumbersOnly
                                        }
                                    })}
                                />
                                {errors.lastName && <p className="text-danger">{errors.lastName.message}</p>}
                            </div>

                            <div className="col-md-12 mt-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Email"
                                    {...register('email', {
                                        required: "Email is required",
                                        pattern: {
                                            value: emailPattern,
                                            message: "Invalid Email"
                                        },
                                        validate: noLeadingOrTrailingSpaces
                                        //pattern: /^\S+@\S+$/i 
                                    }

                                    )}
                                />
                                {errors.email && <p className="text-danger">{errors.email.message}</p>}

                            </div>

                            <div className="col-md-12 mt-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Location"
                                    ref={inputRef}
                                    // value={}
                                    id="addressInput"
                                />
                                {addressError &&
                                    <p className='text-danger'>{addressError}</p>
                                }
                                {
                                    (errors.email || errors.firstName || errors.lastName || errors.password || errors.confirmPassword) && inputRef.current.value === ""
                                    && <p className='text-danger'> Location is required</p>
                                }
                            </div>
                            <p className='text-secondary' style={{ fontSize: '0.85rem', margin: 0 }}>Note: Elevate your drone data journey! Share your approximate location for a personalized
                                experience and exciting project opportunities.</p>

                            {/* <div className="col-md-12 mt-3">
                                <select
                                    // className={`form-select form-control ${selectbox == '' ? 'text-secondary' : ''}}`}
                                    {...register('country', { required: true })}
                                    className={`form-select form-control ${country ? '' : 'text-secondary'}`}

                                >
                                    <option key='' value='' className="text-secondary">Country</option>
                                    {
                                        coutries.map((country) => {
                                            return (
                                                <option key={country.code} value={country.name}>{country.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                {(errors.country && errors.country.type === "required") && <p className="text-danger">Country is required</p>}
                            </div> */}

                            <div className="col-md-12 mt-3">
                                <div className="input-group">
                                    <input
                                        // type="password"
                                        type={showPassword ? 'text' : 'password'} // Conditional rendering based on showPassword state
                                        className="form-control"
                                        placeholder="Password"
                                        autocomplete="off" // Add this line to disable autocomplete
                                        {...register('password', { required: true, minLength: 8, pattern: passwordPattern })}
                                    />
                                    <button className='append-btn' type='button' onClick={() => setShowPassword(!showPassword)}>{showPassword ?
                                        <img src={eye} alt="" srcset="" />
                                        : <img src={hide} alt="" srcset="" />
                                    }</button>
                                </div>
                                <div>
                                    {errors.password && errors.password.type === "required" && (
                                        <p className="text-danger">Password is required</p>
                                    )}
                                    {errors.password && errors.password.type === "minLength" && (
                                        <p className="text-danger">Password must be at least 8 characters long</p>
                                    )}
                                    {errors.password && errors.password.type === "pattern" && (
                                        <p className="text-danger">Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character</p>
                                    )}
                                </div>


                            </div>
                            <div className="col-md-12 mt-3">
                                <div className="input-group">
                                    <input
                                        // type="password"
                                        type={showConfirmPassword ? 'text' : 'password'} // Conditional rendering based on showPassword state
                                        className="form-control"
                                        placeholder="Confirm Password"
                                        {...register('confirmPassword', {
                                            required: true, minLength: 8, validate: value => value === password || 'The passwords do not match'
                                        })}
                                    />
                                    <button className='append-btn' type='button' onClick={() => setShowConfirmPassword(!showConfirmPassword)}>{showConfirmPassword ?
                                        <img src={eye} alt="" srcset="" />
                                        : <img src={hide} alt="" srcset="" />
                                    }</button>
                                </div>
                                <div>
                                    {errors.confirmPassword && errors.confirmPassword.type === "required" && (
                                        <p className="text-danger">Confirm Password is required</p>
                                    )}
                                    {errors.confirmPassword && errors.confirmPassword.type === "minLength" && (
                                        <p className="text-danger">Confirm Password must be at least 8 characters long</p>
                                    )}
                                    {errors.confirmPassword && errors.confirmPassword.type === "validate" && (
                                        <p className="text-danger">The passwords do not match</p>
                                    )}
                                </div>

                            </div>
                            <div className="col-md-12 mt-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Refferal Code"
                                    {...register('referral_code', {
                                        // required: "Email is required",
                                        // pattern: {
                                        //     value: emailPattern,
                                        //     message: "Invalid Email"
                                        // },
                                        validate: noLeadingOrTrailingSpaces
                                        //pattern: /^\S+@\S+$/i 
                                    }

                                    )}
                                />
                                {errors.referral_code && <p className="text-danger">{errors.referral_code.message}</p>}

                            </div>
                            {/* <div className="col-md-12 d-flex justify-content-between rememeber-box mb-3">
                                <div className="form-check mt-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="exampleCheck1"
                                    />
                                    <label className="form-check-label" htmlFor="exampleCheck1">
                                        Remember me
                                    </label>
                                </div>
                            </div> */}

                            <div className="col-md-12 mt-3">
                                <button
                                    className="btn btn-primary btn-purple"
                                    type="submit"
                                    style={{ width: "100%" }}
                                >
                                    Sign Up
                                </button>
                                <div className="rememeber-box text-center mt-3" style={{ color: "#3D3D3D80" }}>
                                    Already have an account? <Link to="/login" className="forget-password mt-2">Sign in</Link>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default SignUp;
