import React from 'react'
import './DashbordHeading.css'
import search from '../../assets/images/Search.png'

const DashboardHeading = ({ heading, content, searchQuery, setSearchQuery }) => {
    return (
        <div className='dashboard-heading d-flex justify-content-between align-items-center row'>
            <div className='col-md-6'>
                {heading && <h1>{heading}</h1>}
                {content && <p>{content}</p>}
            </div>
            {/* <div className='col-md-4'>
                <input style={{background:"#3D3D3D0D"}} className='form-control' type="search" name="" id="" />
            </div> */}
            <div className='input-with-icon col-md-4'>
                            <span className="input-icon" style={{padding:"10px"}}>
                                <img src={search} alt="" style={{width:'25px'}}/>
                            </span>
                            <input
                                type="search"
                                className="form-control"
                                style={{ paddingLeft: '40px' }}
                                id="current-password"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            {/* <span className="input-icon-last">
                                <button type='button' onClick={() => setShowOldPassword(!showOldPassword)} >
                                    {showOldPassword ?
                                        <img src={eye} alt="" srcset="" />
                                        : <img src={hide} alt="" srcset="" />
                                    }
                                </button>
                            </span> */}
                        </div>

        </div>
    )
}

export default DashboardHeading