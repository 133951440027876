import { createSlice } from "@reduxjs/toolkit";

const initailState = {
    upgradePlanDetails: null,
    cancelSuccessDailog: false,
    refferalHistory: []
}

export const subscriptionSlice = createSlice({
    name: "subscription",
    initialState: initailState,
    reducers: {
        getUpgradePlanDetailRequest: (state) => {
            state.loading = true;
        },
        getUpgradePlanDetailSuccess: (state, action) => {
            state.loading = false;
            state.upgradePlanDetails = action.payload;
        },
        getUpgradePlanDetailFailure: (state) => {
            state.loading = false;
        },
        cancelUserSubscriptionRequest: (state) => {
            state.loading = true;
        },
        cancelUserSubscriptionSuccess: (state, action) => {
            state.loading = false;
            state.cancelSuccessDailog = true;
            // state.upgradePlanDetails = action.payload;
        },
        cancelUserSubscriptionFailure: (state) => {
            state.loading = false;
        },
        ToggleCancelSuccessDailog: (state, action) => {
            state.cancelSuccessDailog = action.payload;
        },
        sendRefferalCodeRequest: (state) => {
            state.loading = true;
        },
        sendRefferalCodeSuccess: (state, action) => {
            state.loading = false;
        },
        sendRefferalCodeFailure: (state) => {
            state.loading = false;
        },
        getRefferalRequest: (state) => {
            state.loading = true;
        },
        getRefferalSuccess: (state, action) => {
            state.loading = false;
            state.refferalHistory = action.payload
        },
        getRefferalFailure: (state) => {
            state.loading = false;
        },
        reedemSkyPointsRequest: (state) => {
            state.loading = true;
        },
        reedemSkyPointsSuccess: (state, action) => {
            state.loading = false;
            // state.refferalHistory = action.payload
        },
        reedemSkyPointsFailure: (state) => {
            state.loading = false;
        },
    }
})

export const {
    getUpgradePlanDetailFailure, getUpgradePlanDetailRequest, getUpgradePlanDetailSuccess, cancelUserSubscriptionFailure,
    cancelUserSubscriptionSuccess, cancelUserSubscriptionRequest, ToggleCancelSuccessDailog, sendRefferalCodeFailure, sendRefferalCodeSuccess, sendRefferalCodeRequest,
    getRefferalFailure, getRefferalRequest, getRefferalSuccess, reedemSkyPointsRequest, reedemSkyPointsSuccess, reedemSkyPointsFailure
} = subscriptionSlice.actions

export default subscriptionSlice.reducer;