import axios from 'axios'
import { CustomerPurchasePlanFailure, CustomerPurchasePlanRequest, CustomerPurchasePlanSuccess, GetDroneListFailure, GetDroneListRequest, GetDroneListSuccess, GetSubscriptionPlansFailure, GetSubscriptionPlansRequest, GetSubscriptionPlansSuccess, LoginFailure, LoginRequest, LoginSuccess, PaymentHistoryFailure, PaymentHistoryRequest, PaymentHistorySuccess, RegisterFailure, RegisterRequest, RegisterSuccess, UpdateProfileFailure, UpdateProfileRequest, UpdateProfileSuccess, VerifyOtpFailure, VerifyOtpRequest, VerifyOtpSuccess, allSuccess, autoLoginFailure, autoLoginRequest, autoLoginSuccess, changeAuthUserPasswordFailure, changeAuthUserPasswordRequest, changeAuthUserPasswordSuccess, changePasswordFailure, changePasswordRequest, changePasswordSuccess, deleteUserProfileFailure, deleteUserProfileRequest, deleteUserProfileSuccess, forgetPasswordLinkFailure, forgetPasswordLinkRequest, forgetPasswordLinkSuccess, saveDroneFailure, saveDroneRequest, saveDroneSuccess } from '../reducers/user.reducers';
import { API_URL } from '../../constant/app-constants';
import { toast } from 'react-toastify';
import countries from '../../constant/countryCodes.json'
import { userSpace } from './upload.actions';
import { setAdmin } from '../reducers/offers.reducers'

/**
 * Registers a new user.
 *
 * @param {string} first_name - The first name of the user.
 * @param {string} last_name - The last name of the user.
 * @param {string} email - The email of the user.
 * @param {object} country - The country object containing localAddress, latitude, and longitude.
 * @param {string} country_code - The country code of the user.
 * @param {string} password - The password of the user.
 * @param {function} navigate - Function to navigate to different routes.
 * @returns {function} Dispatch function to handle registration process.
 */
export const registerUser = (first_name, last_name, email, country, country_code, password, navigate, referral_code) => async (dispatch) => {
    try {
        dispatch(RegisterRequest())
        const { data } = await axios.post(API_URL + '/api/v1/user/signUp',
            { first_name, last_name, email, address: country.localAddress, latitude: country.latitude, longitude: country.longitude, country_code: "", password, referralCode:referral_code },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );

        toast.success('Registration successful');
        navigate('/otpVerify');
        dispatch(RegisterSuccess(data.data))

    } catch (error) {
        dispatch(RegisterFailure(error))
        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error('Registration failed. Please try again.');
        }
    }
}

/**
 * Logs in a user.
 *
 * @param {string} email - The email of the user.
 * @param {string} password - The password of the user.
 * @param {function} navigate - Function to navigate to different routes.
 * @returns {function} Dispatch function to handle login process.
 */
export const loginUser = (email, password, navigate) => async (dispatch) => {
    try {
        dispatch(LoginRequest())

        const { data } = await axios.post(API_URL + '/api/v1/user/signIn',
            { email, password },
            {
                headers: {
                    "Content-Type": "application/json"
                }
            });

        if (data.data.isAdmin) {
            toast.success(data.message);
            dispatch(setAdmin(data.data));
            dispatch(allSuccess());
            localStorage.setItem('adminToken', data.data.token)
            navigate('/admin-offers');
            return
        }

        if (data.data.is_otp_verified) {
            toast.success(data.message);
            navigate('/subscripiton-plan');
            dispatch(LoginSuccess(data.data));
        } else {
            const userData = {
                id: data.data.id,
                first_name: data.data.first_name,
                last_name: data.data.last_name,
                email: data.data.email
            }
            dispatch(RegisterSuccess(userData))
            dispatch(resendOtp(data.data.email));
            navigate('/otpVerify')
        }

    } catch (error) {
        dispatch(LoginFailure(error))
        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error('Login failed. Invalid Email or Password.');
        }
    }
}

/**
 * Verifies the OTP for a user.
 *
 * @param {string} email - The email of the user.
 * @param {string} otp - The OTP to verify.
 * @param {function} navigate - Function to navigate to different routes.
 * @returns {function} Dispatch function to handle OTP verification process.
 */
export const verifyOtp = (email, otp, navigate) => async (dispatch) => {
    try {
        dispatch(VerifyOtpRequest())

        const { data } = await axios.post(API_URL + '/api/v1/user/verifyOtp',
            { email, otp },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );

        toast.success('Otp Verified Please Login!');
        navigate('/login');
        dispatch(VerifyOtpSuccess(data))

    } catch (error) {
        dispatch(VerifyOtpFailure(error))
        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error('Registration failed. Please try again.');
        }
    }
}

/**
 * Resends the OTP to the user's email.
 *
 * @param {string} email - The email of the user.
 * @returns {function} Dispatch function to handle resending OTP.
 */
export const resendOtp = (email) => async (dispatch) => {
    try {
        dispatch(VerifyOtpRequest())

        const { data } = await axios.post(API_URL + '/api/v1/user/resendOtp',
            { email },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );

        toast.success(data.message);
        dispatch(VerifyOtpSuccess())

    } catch (error) {
        dispatch(VerifyOtpFailure(error))
        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error('Resend Email Failed!');
        }
    }
}

/**
 * Sends a password reset link to the user's email.
 *
 * @param {string} email - The email of the user.
 * @returns {function} Dispatch function to handle sending password reset link.
 */
export const forgetPasswordLink = (email) => async (dispatch) => {
    try {
        dispatch(forgetPasswordLinkRequest())

        const { data } = await axios.post(API_URL + '/api/v1/user/forgetPassword',
            { email },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );
        toast.success(data.message);
        dispatch(forgetPasswordLinkSuccess())

    } catch (error) {
        dispatch(forgetPasswordLinkFailure(error))
        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error('Something went wrong!');
        }
    }
}

/**
 * Changes the user's password.
 *
 * @param {string} token - The token for password reset.
 * @param {string} password - The new password.
 * @param {function} navigate - Function to navigate to different routes.
 * @returns {function} Dispatch function to handle password change.
 */
export const ChangePasswordAction = (token, password, navigate) => async (dispatch) => {
    try {
        dispatch(changePasswordRequest())

        const { data } = await axios.post(API_URL + '/api/v1/user/resetPassword?token=' + token,
            { password },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );

        toast.success(data.message);
        navigate('/login');
        dispatch(changePasswordSuccess())

    } catch (error) {
        dispatch(changePasswordFailure(error))
        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error('Something went wrong!');
        }
    }
}

/**
 * Retrieves subscription plans.
 *
 * @param {string} planType - The type of the plan to retrieve.
 * @returns {function} Dispatch function to handle fetching subscription plans.
 */
export const getSubscripitonPlans = (planType) => async (dispatch) => {
    try {
        dispatch(GetSubscriptionPlansRequest())

        const { data } = await axios.get(API_URL + '/api/v1/subscription/getSubscriptionPlans?planType=' + planType,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );

        dispatch(GetSubscriptionPlansSuccess(data.data))

    } catch (error) {
        dispatch(GetSubscriptionPlansFailure())
        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error('Something went wrong!');
        }
    }
}

/**
 * Handles customer purchase of a plan.
 *
 * @param {string} planId - The ID of the plan to purchase.
 * @param {string} paymentMethod - The payment method to use.
 * @param {function} navigate - Function to navigate to different routes.
 * @returns {function} Dispatch function to handle plan purchase.
 */
export const customerPurchasePlan = (planId, paymentMethod, navigate) => async (dispatch) => {
    try {
        dispatch(CustomerPurchasePlanRequest())
        const token = localStorage.getItem('token')

        const { data } = await axios.post(API_URL + '/api/v1/plans/customerPurchasePlan',
            { planId: planId, paymentMethod: paymentMethod },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            },
        );

        dispatch(CustomerPurchasePlanSuccess(data.data))

    } catch (error) {
        dispatch(CustomerPurchasePlanFailure())
        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error('Something went wrong!');
        }
    }
}

/**
 * Updates the user's profile.
 *
 * @param {FormData} formdata - The form data containing profile updates.
 * @returns {function} Dispatch function to handle profile update.
 */
export const UpdateProfile = (formdata) => async (dispatch) => {
    try {
        dispatch(UpdateProfileRequest())
        const token = localStorage.getItem('token')

        const { data } = await axios.post(API_URL + '/api/v1/user/updateProfile',
            formdata,
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                },
            },
        );

        toast.success(data.message);
        dispatch(UpdateProfileSuccess(data.data))

    } catch (error) {
        dispatch(UpdateProfileFailure())
        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error('Something went wrong!');
        }
    }
}

/**
 * Retrieves the payment history of the user.
 *
 * @returns {function} Dispatch function to handle fetching payment history.
 */
export const GetPaymentHistory = () => async (dispatch) => {
    try {
        dispatch(PaymentHistoryRequest())
        const token = localStorage.getItem('token')

        const { data } = await axios.get(API_URL + '/api/v1/subscription/getPaymentHistory',
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                },
            },
        );

        dispatch(PaymentHistorySuccess(data.data))

    } catch (error) {
        dispatch(PaymentHistoryFailure())
        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error('Something went wrong!');
        }
    }
}

/**
 * Changes the authenticated user's password.
 *
 * @param {string} new_password - The new password.
 * @param {string} old_password - The old password.
 * @returns {function} Dispatch function to handle password change.
 */
export const changeUserPassword = (new_password, old_password) => async (dispatch) => {
    try {
        dispatch(changeAuthUserPasswordRequest())
        const token = localStorage.getItem('token')

        const { data } = await axios.post(API_URL + '/api/v1/user/changePassword',
            { new_password, old_password },
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                },
            },
        );

        toast.success(data.message);
        dispatch(changeAuthUserPasswordSuccess())

    } catch (error) {
        dispatch(changeAuthUserPasswordFailure())
        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error('Something went wrong!');
        }
    }
}

/**
 * Saves subscription details.
 *
 * @param {object} obj - The subscription details object.
 * @param {function} [navigate=null] - Optional function to navigate to different routes.
 * @returns {function} Dispatch function to handle saving subscription details.
 */
export const SubscriptionDetails = (obj, navigate = null) => async (dispatch) => {
    try {
        const token = localStorage.getItem('token')

        const { data } = await axios.post(API_URL + '/api/v1/subscription/saveSubscriptionDetails',
            obj,
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                },
            },
        );

        if (navigate) {
            navigate('/dashboard');
            toast.success("Free Plan is Activated!")
        }

        dispatch(userSpace())

    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error('Something went wrong!');
        }
    }
}

/**
 * Automatically logs in the user if a valid token is found.
 *
 * @param {function} [navigate=null] - Optional function to navigate to different routes.
 * @returns {function} Dispatch function to handle auto login.
 */
export const autoLogin = (navigate = null) => async (dispatch) => {
    try {
        const token = localStorage.getItem('token')
        dispatch(autoLoginRequest())
        const { data } = await axios.post(API_URL + '/api/v1/user/autoLogin',
            {},
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                },
            },
        );
        dispatch(autoLoginSuccess(data.data))
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error('Session Expired!');
        }
        navigate('/login')
        dispatch(autoLoginFailure())
    }
}

/**
 * Retrieves the list of drones.
 *
 * @returns {function} Dispatch function to handle fetching drone list.
 */
export const getDroneList = () => async (dispatch) => {
    try {
        const token = localStorage.getItem('token')
        dispatch(GetDroneListRequest())
        const { data } = await axios.get(API_URL + '/api/v1/drone/getDrone',
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            },       
        );
        dispatch(GetDroneListSuccess(data.data))
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error('Something Went Wrong!');
        }
        dispatch(GetDroneListFailure())
    }
}

/**
 * Saves drone data.
 *
 * @param {array} user_drone_list - The list of user drones.
 * @returns {function} Dispatch function to handle saving drone data.
 */
export const saveDroneData = (user_drone_list) => async (dispatch) => {
    try {
        const token = localStorage.getItem('token')
        dispatch(saveDroneRequest())
        const { data } = await axios.post(API_URL + '/api/v1/drone/saveDrone',
            { user_drone_list },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            },
        );
        toast.success(data.message)
        dispatch(saveDroneSuccess())
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error('Something Went Wrong!');
        }
        dispatch(saveDroneFailure())
    }
}
/**
 * Delete User.
 *
 * @param {email, password} - email password
 * @returns {function} Dispatch function to handle saving drone data.
 */
export const DeleteUserProfile = (email, password) => async (dispatch) => {
    try {
        dispatch(deleteUserProfileRequest())
        const { data } = await axios.post(API_URL + '/api/v1/user/deleteUser',
            { email, password },
            {
                headers: {
                    "Content-Type": "application/json",
                    // "Authorization": `Bearer ${token}`
                },
            },
        );
        toast.success(data.message)
        dispatch(deleteUserProfileSuccess())
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error('Something Went Wrong!');
        }
        dispatch(deleteUserProfileFailure())
    }
}
