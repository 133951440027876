import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import uploadFile from '../../assets/images/uploadFile.png'
import { Uploader } from '../../Utils/Upload';
import { useDispatch, useSelector } from 'react-redux';
import { checkFileExists, getFilesAndFolders, saveSmallFileToDB, userSpace } from '../../store/actions/upload.actions';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, useMediaQuery } from '@mui/material'
import CircularProgressWithLabel from '../CircularProgressWithLabel/CircularProgressWithLabel';
import { calculateChunkSize, calculateSHA1 } from '../../Utils/pipes';
import axios from 'axios';
import { API_URL } from '../../constant/app-constants';
import { toast } from 'react-toastify';
const FileFolderDropzone = ({ path }) => {
    const dispatch = useDispatch();
    const isMobileScreen = useMediaQuery('(max-width:600px)')

    const [isDragActive, setIsDragActive] = useState(false);
    const [files, setFiles] = useState([]);
    const [uploader, setUploader] = useState(undefined)
    const [totalProgress, setTotalProgress] = useState(0);
    const [totalSize, setTotalSize] = useState(0)
    const [filesProgress, setFilesProgress] = useState([]);
    const user = useSelector((state) => state.user.user)
    const [cancelUploadDialogOpen, setCancelUploadDialogOpen] = useState(false);
    const [uploaders, setUploaders] = useState([]);
    const [uploadToCancel, setUploadToCancel] = useState(null);
    const [completeUploading, setCompleteUploading] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false); // State to control the dialog visibility


    const onDrop = async acceptedFiles => {

        if (acceptedFiles.length === 0) {
            toast.error("Could not upload Empty Folder!")
            return;
        }

        const largeFiles = acceptedFiles.filter((file) => file.size > 30 * 1024 * 1024)
        const smallFiles = acceptedFiles.filter((file) => file.size <= 30 * 1024 * 1024)
        const filesResults = await Promise.all(acceptedFiles.map(file => checkFileExists(file, path)));

        for (const result of filesResults) {
            if (!result.isStorageAvailable) {
                toast.error('Storage limit reached. Please upgrade your plan to continue uploading files');
                return;
            }
        }
        const existingFile = filesResults.find(result => result.isFileExist)
        if (existingFile) {
            toast.error(`${existingFile.file.name} file is already exits!`)
            return;
        }
        setDialogOpen(true);
        setTotalSize(acceptedFiles.reduce((acc, file) => acc + file.size, 0));
        // For largeFile Uploads 
        largeFiles.forEach(file => {
            const videoUploaderOptions = {
                fileName: file.name.split('.')[0],
                file: file,
                chunkSize: calculateChunkSize(file.size)
            };

            setFilesProgress(prevFilesProgress => [...prevFilesProgress, { percentage: 0, fileName: file.name.split('.')[0], fileSize: file.size }])
            const uploader = new Uploader(videoUploaderOptions, user.id, path, handleUploadComplete);
            // setUploader(uploader);
            setUploaders(prevUploaders => [...prevUploaders, uploader]);

            uploader.onProgress(({ percentage, fileName }) => {

                setFilesProgress(prevFilesProgress =>
                    prevFilesProgress.map(item =>
                        item.fileName === fileName ? { ...item, percentage: percentage } : item
                    )
                );
            });

            uploader.start();

            // Add the file to the state
            setFiles(prevFiles => [
                ...prevFiles,
                { name: file.name, progress: 0 }
            ]);

        });

        // For smallFile Uploads 
        smallFiles.forEach(async (file) => {

            const fileName = file.name.split('.')[0]
            setFilesProgress(prevFilesProgress => [...prevFilesProgress, { percentage: 0, fileName: fileName, fileSize: file.size }])

            const { data } = await axios.post(API_URL + '/api/v1/uploads/getSmallFilePreSignedUrls', { userId: user.id, absolutePath: path, path: file.path });

            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = async (event) => {
                const arrayBuffer = event.target.result;
                // const hash = CryptoJS.SHA1(CryptoJS.enc.Latin1.parse(arrayBuffer)); 
                const hash = await calculateSHA1(arrayBuffer);
                // const hash = CryptoJS.SHA1(arrayBuffer).toString();s
                const xhr = new XMLHttpRequest();
                let pathToFile;

                if (path === '/') {
                    if (file.path === file.name) {
                        pathToFile = `upload/${user.id}/${file.path}`
                    } else {
                        pathToFile = `upload/${user.id}${file.path}`
                    }
                } else {
                    if (file.path === file.name) {
                        pathToFile = `upload/${user.id}${path}/${file.path}`
                    } else {
                        pathToFile = `upload/${user.id}${path}${file.path}`
                    }
                }

                xhr.addEventListener("load", function () {
                    if (xhr.status >= 200 && xhr.status < 300) {
                        const responseText = xhr.responseText; // Capture the response body as text
                        // Parse the response based on its format (JSON, XML, etc.)
                        const responseData = JSON.parse(responseText); // Assuming JSON format
                        dispatch(saveSmallFileToDB(user.id, pathToFile, file.size, responseData.fileId))
                    }
                });
                // Replace with your desired path

                xhr.open("POST", data.singleUploadUrl);
                // xhr.setRequestHeader("Content-Type", file.type); // Set actual content type
                xhr.setRequestHeader("Authorization", data.authorizationToken); // Replace with authorization logic
                xhr.setRequestHeader("Content-Type", "b2/x-auto");
                xhr.setRequestHeader('X-Bz-Content-Disposition', `attachment; filename="${encodeURIComponent(pathToFile)}"`);
                xhr.setRequestHeader("X-Bz-File-Name", encodeURIComponent(pathToFile));
                xhr.setRequestHeader("X-Bz-Content-Sha1", hash);

                xhr.upload.addEventListener("progress", (event) => {
                    const uploadProgress = Math.floor((event.loaded / event.total) * 100);
                    setFilesProgress((prevFiles) => {
                        return prevFiles.map((item) => {
                            return item.fileName === fileName ? { ...item, percentage: uploadProgress } : item;
                        })
                    })
                });

                xhr.send(arrayBuffer);
            };

            reader.onerror = (error) => {
                console.error('Failed to read file:', error);
                toast.error("Uploading Failed!")
                // Handle file reading error
            };

        });
    } // Only include user as dependency, since files is updated inside the function

    useEffect(() => {
        const totalPercentage = filesProgress.reduce((acc, file) => acc + file.percentage * (file.fileSize / totalSize), 0);
        setTotalProgress(totalPercentage);
    }, [uploader, filesProgress])

    useEffect(() => {
        if (Math.floor(totalProgress) === 100 || Math.ceil(totalProgress) === 100) {
            setTimeout(() => {
                setDialogOpen(false);
                setTotalProgress(0)
                setFilesProgress([])
                dispatch(getFilesAndFolders(path))
                // dispatch(userSpace())
            }, 2000)
        }
    }, [totalProgress])

    useEffect(() => {
        if (uploadToCancel !== null) {
            const canceledUploader = uploaders[uploadToCancel];
            if (canceledUploader) {
                canceledUploader.cancel();
                setUploaders(prevUploaders => prevUploaders.filter((_, index) => index !== uploadToCancel));
            }
            setUploadToCancel(null);
        }
    }, [uploadToCancel]);


    const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: true, directory: true });

    const handleDragEnter = () => {
        setIsDragActive(true);
    };

    const handleDragLeave = () => {
        setIsDragActive(false);
    };

    const cancelUpload = (index, filename) => {
        const canceledUploader = uploaders[index];
        if (canceledUploader) {
            canceledUploader.abort();
            setUploaders(prevUploaders => prevUploaders.filter((_, i) => i !== index));
            setFilesProgress(prevFiles => prevFiles.filter((file) => file.fileName !== filename))
        }
    };

    const handleUploadComplete = () => {
        // Handle upload completion here, for example:
        setCompleteUploading(completeUploading => completeUploading + 1);
        dispatch(getFilesAndFolders(path))
        // dispatch(userSpace())
        setTimeout(() => {
            setUploaders([])
        }, 2000)
    };


    return (
        <div>
            <div
                {...getRootProps()}
                style={isDragActive ? dropzoneActiveStyle : dropzoneStyle}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
            >
                <input
                    type='file'
                    multiple
                    {...getInputProps()}
                />
                <p style={{
                    fontFamily: 'Montserrat',
                    fontSize: '20px'
                }}> <img className='bi' src={uploadFile} alt="" srcset="" /> <b>Click or drag file/folder</b>  to this area to upload</p>
            </div>
            <div>
                <Dialog
                    open={dialogOpen}
                    scroll='paper'
                    PaperProps={{
                        sx: {
                            width: isMobileScreen ? "90vw" : "50%",
                            height: "50vh"
                        }
                    }}
                >
                    <DialogTitle textAlign='center'>Uploading Files</DialogTitle>
                    <DialogContent >
                        <div style={{ display: 'flex', flexDirection: 'column', maxHeight: "60vh !important" }}>
                            <div className='mt-3 mb-3' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >
                                <CircularProgressWithLabel size={100} value={totalProgress} />
                            </div>
                            {filesProgress.length > 0 && filesProgress.map((file, index) => (
                                <div key={file.name} style={{ display: 'flex', alignItems: 'center', marginBottom: '1vmax', justifyContent: 'space-between' }}>
                                    <p style={{ marginRight: '10px' }}>{file.fileName}</p>
                                    <CircularProgressWithLabel size={40} value={file.percentage} />
                                </div>
                            ))}
                        </div>
                    </DialogContent>
                    {/* <DialogActions>
                        <Button onClick={() => setDialogOpen(false)}>Cancel Upload</Button>
                    </DialogActions> */}
                </Dialog>
            </div>

        </div>
    );
};

const dropzoneStyle = {
    maxWidth: "1000px",
    border: '2px dashed #ccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer'
};

const dropzoneActiveStyle = {
    maxWidth: "1000px",
    border: '2px dashed #007bff',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    backgroundColor: '#f0f0f0'
};

export default FileFolderDropzone;