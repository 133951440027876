import { API_URL } from '../../constant/app-constants';
import { toast } from 'react-toastify';
import axios from 'axios';
import { cancelUserSubscriptionFailure, cancelUserSubscriptionRequest, cancelUserSubscriptionSuccess, getRefferalFailure, getRefferalRequest, getRefferalSuccess, getUpgradePlanDetailFailure, getUpgradePlanDetailRequest, getUpgradePlanDetailSuccess, reedemSkyPointsFailure, reedemSkyPointsRequest, reedemSkyPointsSuccess, sendRefferalCodeFailure, sendRefferalCodeRequest, sendRefferalCodeSuccess } from '../reducers/subscription.reducers';
import { updateUserPoints } from '../reducers/user.reducers';

export const getUpgradePlanDetails = (planType) => async (dispatch) => {
    try {
        dispatch(getUpgradePlanDetailRequest())
        const token = localStorage.getItem("token");

        const { data } = await axios.get(API_URL + '/api/v1/subscription/getUpgradePlanDetails?planType=' + planType,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            },
        );

        dispatch(getUpgradePlanDetailSuccess(data.data))

    } catch (error) {
        dispatch(getUpgradePlanDetailFailure())
        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error('File uploading Failed!');
        }
    }
}


export const cancelUserSubscription = () => async (dispatch) => {
    try {
        dispatch(cancelUserSubscriptionRequest())
        const token = localStorage.getItem("token");

        const { data } = await axios.post(API_URL + '/api/v1/subscription/cancel',
            {},
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            },
        );
        console.log("data=>>11", data);
        dispatch(cancelUserSubscriptionSuccess(data.data))

    } catch (error) {
        dispatch(cancelUserSubscriptionFailure())
        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error('Something went Wrong!');
        }
    }
}


export const sendRefferalCode = (email, refferalCode) => async (dispatch) => {
    try {
        dispatch(sendRefferalCodeRequest())
        const token = localStorage.getItem("token");

        const { data } = await axios.post(API_URL + '/api/v1/user/refferalCode',
            { email, refferalCode },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            },
        );
        toast.success(data.message);
        dispatch(sendRefferalCodeSuccess())

    } catch (error) {
        dispatch(sendRefferalCodeFailure())
        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error('Something went Wrong!');
        }
    }
}

export const getReferralHistory = () => async (dispatch) => {
    try {
        dispatch(getRefferalRequest())
        const token = localStorage.getItem("token");

        const { data } = await axios.get(API_URL + '/api/v1/user/refferalHistory',
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            },
        );
        dispatch(getRefferalSuccess(data.data))

    } catch (error) {
        dispatch(getRefferalFailure())
        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error('Something went Wrong!');
        }
    }
}

export const reedemSkyPoints = (name, email, pointsUsed) => async (dispatch) => {
    try {
        dispatch(reedemSkyPointsRequest())
        const token = localStorage.getItem("token");

        const { data } = await axios.post(API_URL + '/api/v1/user/reedem',
            { name, email, pointsUsed },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            },


        );

        dispatch(getReferralHistory())
        dispatch(reedemSkyPointsSuccess())
        dispatch(updateUserPoints(pointsUsed))
        toast.success(data.message)
        return false
    } catch (error) {
        dispatch(reedemSkyPointsFailure())
        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error('Something went Wrong!');
        }
        return true
    }
} 
