import { API_URL } from '../../constant/app-constants';
import { toast } from 'react-toastify';
import { createOfferFailure, createOfferRequest, createOfferSuccess } from '../reducers/offers.reducers';
import axios from 'axios';


export const createOffer = (formdata) => async (dispatch) => {
    try {
        dispatch(createOfferRequest())
        const token = localStorage.getItem("adminToken");

        const { data } = await axios.post(API_URL + '/api/v1/offer',
            formdata,
            {
                headers: {
                    // "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            },
        );
        toast.success(data.message)
        dispatch(createOfferSuccess())

    } catch (error) {
        dispatch(createOfferFailure())
        if (error.response && error.response.data && error.response.data.message) {
            if (error.response.status === 403) {
                toast.error('Session Expired!')
            } else {
                toast.error(error.response.data.message);
            }
        } else {
            toast.error('Something Went Wrong!');
        }
    }
}
