import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    TextField
} from '@mui/material';
import { API_URL } from '../../constant/app-constants';

const OffersTable = () => {
    const [offers, setOffers] = useState([]);
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState('');
    const [total, setTotal] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        const fetchOffers = async () => {
            try {
                const token = localStorage.getItem('adminToken')
                const res = await axios.get(API_URL + `/api/v1/offer`, {
                    params: { page: page + 1, limit: rowsPerPage, search }, headers: {
                        Authorization: 'Bearer ' + token
                    }
                });
                setOffers(res.data.offers);
                setTotal(res.data.total);
            } catch (error) {
                console.error('Error fetching offers:', error);
            }
        };

        fetchOffers();
    }, [page, search, rowsPerPage]);

    const handleSearch = (event) => {
        setPage(0); // Reset to first page on new search
        setSearch(event.target.value);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Paper>
            {/* <TextField
                label="Search offers"
                variant="outlined"
                fullWidth
                margin="normal"
                value={search}
                onChange={handleSearch}
            /> */}
            <input
                type="search"
                name=""
                id=""
                className='form-control mb-2'
                label="Search offers"
                value={search}
                onChange={handleSearch}
                placeholder='Search Offer'
            />
            <TableContainer sx={{maxHeight:'65vh'}}>
                <Table>
                    <TableHead>
                        <TableRow  >
                            {/* <TableCell style={{ fontWeight: 700 }} >ID</TableCell> */}
                            <TableCell style={{ fontWeight: 700 }}>TITLE</TableCell>
                            <TableCell style={{ fontWeight: 700 }}>DESCRIPTION</TableCell>
                            <TableCell style={{ fontWeight: 700 }}>IMAGE</TableCell>
                            {/* <TableCell style={{ fontWeight: 700 }}>AFFILATE LINK</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {offers.map((offer) => (
                            <TableRow key={offer.id}>
                                {/* <TableCell >{offer.id}</TableCell> */}
                                <TableCell>{offer.title}</TableCell>
                                <TableCell style={{ width: '70%' }}>{offer.description}</TableCell>
                                <TableCell>
                                    <img src={offer.image} alt={offer.title} style={{ width: '100px' }} />
                                </TableCell>
                                {/* <TableCell>
                                    <a href={offer.affiliate_link} target="_blank" rel="noopener noreferrer">
                                        {offer.affiliate_link}
                                    </a>
                                </TableCell> */}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
};

export default OffersTable;
