import React from 'react'
import companyLogo from '../../assets/images/companyLogo.png'
import './CompanyLogo.css'
import { siteUrl } from '../../constant/app-constants'

const CompanyLogo = () => {
    return (
        <a className='companyLogo' href={siteUrl} >
            A <span> <img src={companyLogo} alt="" srcset="" /> </span> SkyDatalogix Company
        </a>
    )
}

export default CompanyLogo