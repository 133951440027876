import React from 'react'
import PointArrow from '../../assets/images/PointArrow.png'
import './ReferralPoint.css'

const ReferralPoint = ({ heading, description }) => {
    return (
        <div className="bonus-item">
            {/* <FontAwesomeIcon icon={faArrowRight} className="icon" /> */}
            <div style={{ margin: '2px 5px' }}>
                <img src={PointArrow} alt="" />
            </div>
            <div>
                <div className="bonus-header">{heading}:</div>
                {typeof description === "string" ?
                    <div className="bonus-description">
                        {description}
                    </div> :
                    <div className="bonus-description">
                        {
                            description.map((item) => (
                                <li>
                                    {item}
                                </li>
                                
                            ))
                        }
                    </div>
                }

            </div>
        </div>)
}

export default ReferralPoint