import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import CoinStar from '../../assets/images/CoinStar.png'
import './ReferralDailog.css'
import { useDispatch, useSelector } from 'react-redux';
import { reedemSkyPoints } from '../../store/actions/subscription.actions';
import { useForm } from 'react-hook-form';

const ReferralDailog = ({ open, onClose }) => {
    const dispatch = useDispatch()
    const user = useSelector((state) => state.user.user)
    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    // const generateGiftCard = async () => {
    //     const success = await 
    //     if (!success) {
    //         onClose()
    //     }
    // }

    const onSubmit = async (data) => {
        console.log('Form data:', data);
        const success = await dispatch(reedemSkyPoints(user.first_name + " " + user.last_name, user.email, data.points))
        if (!success) {
            onClose()
        }
        reset()
        // Proceed with form submission logic
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} sx={{ fontFamily: 'Montserrat' }}>
                <form style={{ padding: '1vmax' }} onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        {/* <h3>We're sad to see you go!</h3>
                        <p>Before you cancel, please let us know why you're leaving:</p> */}
                        <div id='coin-star'>
                            <img src={CoinStar} alt="" />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', transform: 'translateY(-10px)' }}>
                            <h5 style={{ fontWeight: 600 }} >Available  Skypoints</h5>
                            <h3 style={{ fontWeight: 600 }}>{user && user.points}</h3>
                        </div>
                    </DialogTitle>
                    <DialogContent >
                        {/* <div className='points-box'>
                        <div>
                            <p>Skypoints</p>
                            <h5>2000</h5>
                        </div>
                        <div>
                            <p>You’ll Get</p>
                            <h5>$20</h5>
                        </div>
                    </div> */}
                        <div>
                            <label style={{ fontSize: '14px', marginBottom: '0', color: '#3D3D3D' }} className="form-label"

                            >Enter points</label>
                            <input type="number" className="form-control"
                                {...register('points', {
                                    required: 'Points are required.',
                                    pattern: {
                                        value: /^[0-9]*$/,
                                        message: 'Please enter a valid number for points.'
                                    },
                                    min: {
                                        value: 2000,
                                        message: 'Points must be equal or greater than 2000.'
                                    },
                                    max: {
                                        value: 100000,
                                        message: 'Points must be equal or less than 100000 (1 lac).'
                                    }
                                })}

                            />
                            {errors.points && <p style={{ color: 'red', fontSize: '12px' }}>{errors.points.message}</p>}

                        </div>
                        <div>
                            <p style={{ fontSize: '13px', marginBottom: '0', color: '#3D3D3D', textAlign: 'center', marginTop: '1vmax' }} >
                                Convert you skypoints to Amazon e-gift cards (100 points = USD 1) <br />
                                You need minimum 2000 points to redeem
                            </p>
                            {/* <p style={{ fontSize: '13px', marginBottom: '0', color: '#3D3D3D' }}>
                                You need minimum 2000 points to redeem
                            </p> */}

                        </div>

                    </DialogContent>
                    <DialogActions className='flex flex-column col-md-12 p-2'>
                        <button type='submit' className="voilet-btn mb-3" style={{ paddingLeft: '20px', paddingRight: '20px' }} >
                            Generate Gift Card
                        </button>
                        <p style={{ fontSize: '13px', marginBottom: '0', color: '#3D3D3D', textAlign: 'center' }}>
                            Click to generate your Amazon e-gift card. <br />
                            It will be sent to your registered email address.
                        </p>
                    </DialogActions>
                </form>
            </Dialog>
            {/* <Dialog open={isConfirmDialogOpen}>
                    <DialogContent>
                        <h3 style={{ textAlign: 'center' }}>
                            Your Subscription has been
                            cancelled successfully.
                        </h3>
                        <p style={{ textAlign: 'center', color: "#00000080" }}>
                            You will still get to Enjoy all the features under the Free-Tier.                    </p>
                    </DialogContent>
                    <div style={{ marginBottom: "1vmax", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                       
                    </div>
                </Dialog> */}
        </>

    );
};

export default ReferralDailog
    ;
