import React, { useEffect, useState } from 'react'
import { Route, NavLink, Routes, useNavigate } from 'react-router-dom';
import './Dashboard.css'
import logo from "../../assets/images/logo.png"
import home from '../../assets/images/Home.png'
import hub from '../../assets/images/Hub.png'
import chat from '../../assets/images/Chat.png'
import image from '../../assets/images/Image.png'
import youtube from '../../assets/images/Youtube.png'
import trash from '../../assets/images/Trash.png'
import share from '../../assets/images/Share.png'
import hamburger from '../../assets/images/Hamburger_icon.png'
import Home from './Home/Home';
import MediaHub from './MediaHub/MediaHub';
import Photos from './MediaHub/Photos/Photos';
import Videos from './MediaHub/Videos/Videos';
import DeletedItems from './MediaHub/DeletedItems/DeletedItems';
import discount from '../../assets/images/Discount.png';
import jobs from '../../assets/images/Job.png';
import shoppingCart from '../../assets/images/Shopping cart.png';
import Header from '../../components/Header/Header'
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Hidden, IconButton, useMediaQuery } from '@mui/material';
import UserProfile from './UserProfile/UserProfile';
import ChangePassword from './Settings/ChagePassword/ChangePassword';
// import { Help } from '@mui/icons-material';
import Help from './Settings/Help/Help';
import PrivacyPolicy from './Settings/PrivacyPolicy/PrivacyPolicy';
import PaymentHistory from './Settings/PaymentHistory/PaymentHistory';
import useAutoLogin from '../../hooks/useAutoLogin';
import CommingSoon from '../../components/CommingSoon/CommingSoon';
import LinearProgress from '@mui/material/LinearProgress';
import UpgradePlan from './UpgradePlan/UpgradePlan';
import ReferalProgram from './UserProfile/ReferralProgram/ReferralProgram';
import ReferralDetails from './UserProfile/ReferralDetails/ReferralDetails';
import { userSpace } from '../../store/actions/upload.actions';
import { formatBytes } from '../../Utils/pipes';
import OffersList from './Offers/Offers';


const Dashboard = () => {

    useAutoLogin()
    const [showSidebar, setShowSidebar] = useState(false);
    const navigate = useNavigate()
    const storageSpace = useSelector((state) => state.upload.storageSpace)
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false);
    const isMobileScreen = useMediaQuery('(max-width:600px)');
    const isDesktopScreen = useMediaQuery('(min-width:1024px)');
    // const user = useSelector((state) => state.user.user)
    const handleDrawerOpen = () => {
        setOpen(prevOpen => !prevOpen);
    };


    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleLinkClick = () => {
        if (isMobileScreen) {
            setOpen(false); // Close drawer on link click for mobile screens
        }
    };

    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };

    const [mediaHub, setMediaHub] = useState(false);

    const toggleHub = () => {
        setMediaHub(!mediaHub)
    }

    useEffect(() => {
        // Check screen width and set showSidebar state accordingly
        const screenWidth = window.innerWidth;
        setShowSidebar(screenWidth >= 1024); // Show sidebar for screens wider than 1024px
        dispatch(userSpace());
    }, [dispatch]);

    // useEffect(() => {
    //     setTimeout(() => {
    //         if (user && !user.isSubscribed) {
    //             console.log('here')
    //             navigate('/subscripiton-plan')
    //         }
    //     },3000)
    // }, [user])

    const navigateToUpgradePlan = () => {
        navigate("/dashboard/upgrade-plan")
    }



    return (
        <>
            <div className="app">
                <Header isMobileScreen={isMobileScreen} handleDrawerOpen={handleDrawerOpen} isHeaderShow={true} open={open} />
                {/* Content Wrapper */}
                <div className="content-wrapper">
                    { /* Sidebar */}
                    {(isDesktopScreen || (open && isMobileScreen)) &&
                        <aside className={`sidebar hide ${isMobileScreen && 'sidebar-show'}`} style={{ background: '#FCFCFC', zIndex: 1 }}  >
                            <ul className='sidbar-menu'>
                                <li>
                                    <NavLink to='/dashboard' role='button' onClick={handleDrawerClose} >
                                        <img className='bi' src={home} alt="" />
                                        Home
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to='/dashboard/offers'
                                        // style={({ isActive }) => {
                                        //     return isActive ? {
                                        //         boxShadow: '0px 2px 10px 6px rgba(0, 0, 0, 0.1)'
                                        //     } : {};
                                        // }}
                                    >
                                        <img className='bi' src={jobs} alt="" />
                                        Offers
                                        {/* <CommingSoon /> */}

                                    </NavLink>
                                </li>
                                {/* <li className={mediaHub ? 'right-arrow-rotated' : 'right-arrow'}> */}
                                <li>
                                    <NavLink >
                                        <img className='bi' src={hub} alt="" />
                                        Media Hub
                                        <CommingSoon />
                                        {/* <span style={{marginLeft:"1vmax"}} className={mediaHub ? 'right-arrow-rotated' : 'right-arrow'}></span> */}
                                    </NavLink>
                                </li>
                                {mediaHub &&
                                    <ul className='sidbar-menu'>
                                        <li>
                                            <NavLink to='/dashboard/photos'
                                                style={({ isActive }) => {
                                                    return isActive ? {
                                                        boxShadow: '0px 2px 10px 6px rgba(0, 0, 0, 0.1)'
                                                    } : {};
                                                }} >
                                                <img className='bi' src={image} alt="" />
                                                Photos
                                                <CommingSoon />
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/dashboard/videos' style={({ isActive }) => {
                                                return isActive ? {
                                                    boxShadow: '0px 2px 10px 6px rgba(0, 0, 0, 0.1)'
                                                } : {};
                                            }} >
                                                <img className='bi' src={youtube} alt="" />
                                                Videos
                                                <CommingSoon />
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/dashboard/shared' style={({ isActive }) => {
                                                return isActive ? {
                                                    boxShadow: '0px 2px 10px 6px rgba(0, 0, 0, 0.1)'
                                                } : {};
                                            }} >
                                                <img className='bi' src={share} alt="" />
                                                Shared
                                                <CommingSoon />
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/dashboard/deletedItems' style={({ isActive }) => {
                                                return isActive ? {
                                                    boxShadow: '0px 2px 10px 6px rgba(0, 0, 0, 0.1)'
                                                } : {};
                                            }} >
                                                <img className='bi' src={trash} alt="" />
                                                Deleted items
                                                <CommingSoon />
                                            </NavLink>
                                        </li>

                                    </ul>

                                }
                                <li>
                                    <NavLink>
                                        <img className='bi' src={chat} alt="" />
                                        Forum
                                        <CommingSoon />
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink>
                                        <img className='bi' src={discount} alt="" />
                                        Pilot Jobs
                                        <CommingSoon />
                                    </NavLink>
                                </li>

                                <li>
                                    <NavLink>
                                        <img className='bi' src={shoppingCart} alt="" />
                                        Marketplace
                                        <CommingSoon />
                                    </NavLink>
                                </li>
                            </ul>
                            {
                                storageSpace &&
                                <div style={{ padding: '2vmax', marginTop: '30vh', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Box sx={{ width: '90%', }}>
                                        <div className='d-flex justify-content-between'>
                                            <p style={{ fontSize: "10px", color: "#3D3D3D80" }}>
                                                {(formatBytes(storageSpace.planSpace - storageSpace.filledSpace))} GB of {formatBytes(storageSpace.planSpace)} left
                                            </p>
                                            <p style={{ fontSize: "10px", color: "#3D3D3D80" }}>
                                                {(((storageSpace.filledSpace) / storageSpace.planSpace) * 100).toFixed(2)} %
                                            </p>
                                        </div>

                                        <LinearProgress variant="determinate" value={((storageSpace.filledSpace) / storageSpace.planSpace) * 100} sx={{
                                            height: 5,
                                            backgroundColor: '#7E7E7E33', // Background color of zthe progress bar track
                                            '& .MuiLinearProgress-bar': {
                                                backgroundColor: '#7F88E7' // Custom color for the progress bar
                                            }
                                        }} />


                                    </Box>
                                    <button onClick={navigateToUpgradePlan} style={{ width: '150px' }} className='voilet-btn mt-3'>Upgrade plan</button>
                                </div>
                            }
                        </aside>

                    }


                    {/* Main Content */}
                    <main className="main-content" style={{ padding: '2vmax', display: (isMobileScreen && open) && 'none' }} >
                        {/* <Hidden mdUp onClick={toggleSidebar} >
                            <img src={hamburger} alt="" width={'30px'} height={'30px'} />
                        </Hidden> */}
                        <Routes>
                            <Route path='' element={<Home />} />
                            <Route path='photos' element={<Photos />} />
                            <Route path='videos' element={<Videos />} />
                            <Route path='deleted-items' element={<DeletedItems />} />
                            <Route path='profile' element={<UserProfile />} />
                            <Route path='changePassword' element={<ChangePassword />} />
                            <Route path='help' element={<Help />} />
                            <Route path='privacy-policies' element={<PrivacyPolicy />} />
                            <Route path='payment-history' element={<PaymentHistory />} />
                            <Route path='upgrade-plan' element={<UpgradePlan />} />
                            <Route path='referal-program' element={<ReferalProgram />} />
                            <Route path='referral-details' element={<ReferralDetails />} />
                            <Route path='offers' element={<OffersList />} />
                        </Routes>
                    </main>
                </div>
            </div>
        </>
    )
}

export default Dashboard