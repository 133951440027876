import React from 'react'
import "./Header.css"
import logo from "../../assets/images/DJIlogo.png"
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Logout } from '../../store/reducers/user.reducers'
import ProfileMenu from '../ProfileMenu/ProfileMenu'
import companyLogo from "../../assets/images/companyLogo.png"
import CompanyLogo from '../CompanyLogo/CompanyLogo'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material'


const Header = ({ isMobileScreen, handleDrawerOpen, isHeaderShow, open }) => {

  const { isAuthenticated } = useSelector((state) => state.user)
  const navigate = useNavigate()
  const logout = () => {
    navigate('/login')
    Logout()
  }

  return (
    <nav class="navbar navbar-light bg-light custom-nav-bar">

      <a class="navbar-brand" href="#">
        {isMobileScreen &&
          <IconButton color="inherit" edge="start" onClick={handleDrawerOpen} sx={{ marginLeft: '1vmax' }}  >
            {open ? <CloseIcon /> : <MenuIcon /> }
          </IconButton>
        }
        {!isMobileScreen && <img src={logo} class="d-inline-block " alt="" width={65} style={{ paddingLeft: '1vmax' }} />}
        {!isMobileScreen && <CompanyLogo />}

      </a>


      <div class="form-inline">
        {isAuthenticated && isHeaderShow && <ProfileMenu />}
      </div>



    </nav>
  )
}

export default Header;

