import { Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './ReferralProgram.css'
import ReferralImage from '../../../../assets/images/Referral.png'
import RightStar from '../../../../assets/images/RightStar.png'
import LeftStar from '../../../../assets/images/LeftStar.png'
import Copy from '../../../../assets/images/Copy.png'
import Send from '../../../../assets/images/Send.png'
import LeftSideArrow from '../../../../assets/images/LeftSideArrow.png'
import Subtract from '../../../../assets/images/Subtract.png'
import SubtractActed from '../../../../assets/images/yellowStars.png'
import Star from '../../../../assets/images/Star.png'
import Coin from '../../../../assets/images/Coin.png'
import { useForm } from 'react-hook-form';
import { emailPattern } from '../../../../constant/app-constants'
import ReferralTable from '../../../../components/ReferralTable/ReferralTable'
import { useDispatch, useSelector } from 'react-redux'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { getReferralHistory, sendRefferalCode } from '../../../../store/actions/subscription.actions'
import Loader from '../../../../components/Loader/Loader'
import ReferralDailog from '../../../../components/ReferralDailog/ReferralDailog'

const ReferralProgram = () => {
    const isMobileScreen = useMediaQuery('(max-width:600px)')
    const user = useSelector((state) => state.user.user)
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const dispatch = useDispatch()
    const loading = useSelector((state) => state.subscription.loading)
    const referralHistory = useSelector((state) => state.subscription.refferalHistory)
    const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);


    useEffect(() => {
        dispatch(getReferralHistory())
    }, [])

    const onSubmit = (data) => {
        console.log('data-->', data.email, user.refferalCode)
        dispatch(sendRefferalCode(data.email, user.referralCode))
        reset();
    };

    const handleCopy = async () => {
        if (user && user.referralCode) {
            await navigator.clipboard.writeText(user.referralCode)
                .then(() => {

                    console.log('Referral code copied to clipboard!');
                    setOpenSnackbar(true); // Show the Snackbar
                })
                .catch(err => {
                    console.error('Failed to copy the referral code: ', err);
                });
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const handleCloseSecondaryDialog = () => {
        setConfirmDialogOpen(false);
    };

    return (
        <div className='referal-program'>
            {loading && <Loader />}
            <h3 style={{ margin: 0, padding: 0, color: 'black' }} >
                Referral Program
            </h3>
            <div className='referalheading'>
                <h3 style={{ margin: 0, padding: 0, color: 'black', color: '#747DD7', fontWeight: '700' }} >
                    Refer A Friend and Earn Skypoints!
                </h3>
                <div className={`img-container ${!isMobileScreen ? 'spread-image' : ''}`}>
                    <div>
                        <img src={RightStar} alt="" />
                    </div>
                    <div>
                        <img src={ReferralImage} alt="" />
                    </div>
                    <div>
                        <img src={LeftStar} alt="" />
                    </div>
                </div>
            </div>
            <main>
                <div className='head'>
                    <h5>Earn 500 Skypoints for Every Referral</h5>
                    <p>Get 500 Skypoints for each successful referral and signup.
                        Your friend will also receive 500 Skypoints. If your friend purchases any storage subscription plan,
                        then you both also receive more Skypoints every month for an active subscription.</p>
                    <Link to='/dashboard/referral-details'>
                        [Click here to learn more about our referral program and its perks!]
                    </Link>
                    <div className='mt-3' >
                        <label htmlFor="" className='form-label' style={{ fontWeight: '600' }}>Copy your code</label>
                        {
                            user &&
                            <div className='input-group'>
                                <input
                                    type="text"
                                    name=""
                                    id=""
                                    className='form-control'
                                    value={user.referralCode}
                                />                            <div className="input-group-append ms-3"
                                    onClick={handleCopy}
                                >
                                    <button className="btn btn-outline-secondary"
                                        type="button" style={{ padding: '10px 20px', background: '#7F88E7', border: 'none', color: 'white' }}> <img src={Copy} alt=""

                                        /> Copy</button>
                                </div>
                            </div>
                        }

                    </div>
                    <Snackbar
                        open={openSnackbar}
                        autoHideDuration={3000}
                        onClose={handleCloseSnackbar}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    >
                        <MuiAlert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                            Referral code copied to clipboard!
                        </MuiAlert>
                    </Snackbar>
                    <form className='mt-3 mb-3' onSubmit={handleSubmit(onSubmit)}>
                        <label htmlFor="" className='form-label' style={{ fontWeight: '600' }}>Share the referral code </label>
                        <div className='input-group '>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="johndoe@gmail.com"
                                {...register('email', {
                                    required: "Email is required",
                                    pattern: {
                                        value: emailPattern,
                                        message: "Invalid Email"
                                    },
                                })}
                            />
                            <div className="input-group-append ms-3">
                                <button className="btn btn-outline-secondary" type="submit" style={{ padding: '10px 20px', background: '#E8EAFF', border: 'none', color: '#3D3D3D', fontWeight: '500' }}> <img src={Send} alt="" /> Send </button>
                            </div>
                        </div>
                        {errors.email && <p className="text-danger">{errors.email.message}</p>}
                    </form>
                </div>
                <div className="d-flex images-container">
                    <div className={!isMobileScreen ? 'desktop' : ''} >
                        <p style={{ color: '#7F88E7' }}>Total Skypoints Earned</p>
                        {user && <h5>{user.points}</h5>}
                    </div>
                </div>
                <div className='d-flex align-items-center cursor-pointer' onClick={() => setConfirmDialogOpen(true)} role='button' style={!isMobileScreen ? { position: 'relative', top: '-20px', background: 'white', zIndex: 1111, width: '250px', left: '300px' } : {}}>
                    <div>
                        <img src={Coin} alt="" />
                    </div>
                    <div className='d-flex flex-column '>
                        <span style={{ fontSize: '10px', fontWeight: 600 }}>Redeem Skypoints</span>
                        <span style={{ fontSize: '10px', fontWeight: 400 }}>Convert your earned points into money</span>
                    </div>
                    <div>
                        <img className='ms-3' src={LeftSideArrow} alt="" />
                    </div>
                </div>
            </main>
            {
                referralHistory.referralHistory && referralHistory.referralHistory.length > 0 &&
                <>
                    <div className='d-flex'>
                        <div style={{ padding: '.5vmax' }}>
                            <img src={Star} alt="" srcset="" />
                        </div>
                        <div>
                            <h4 style={{ margin: 0, padding: 0, color: 'black' }} >
                                Referral Program
                            </h4>
                            <p style={{ color: '#3D3D3D' }}>
                                View all your past transactions and earned Skypoints.
                            </p>
                        </div>
                    </div>
                    <ReferralTable data={referralHistory.referralHistory}  key2='points' headings={['Recipient Email','Updated','Points']} />
                </>
            }
            {
                referralHistory.reedemHistory && referralHistory.reedemHistory.length > 0 &&
                <>
                    <div className='d-flex'>
                        <div style={{ padding: '.5vmax' }}>
                            <img src={Star} alt="" srcset="" />
                        </div>
                        <div>
                            <h4 style={{ margin: 0, padding: 0, color: 'black' }} >
                                Reedem History
                            </h4>
                            {/* <p style={{ color: '#3D3D3D' }}>
                                View all your past transactions and earned Skypoints.
                            </p> */}
                        </div>
                    </div>
                    <ReferralTable data={referralHistory.reedemHistory} key1={'redemptionType'} key2={'pointsUsed'} headings={['Redemption Type','Updated','Points Used']} />
                </>
            }

            <ReferralDailog
                open={isConfirmDialogOpen}
                onClose={handleCloseSecondaryDialog}
            />
        </div>
    )
}

export default ReferralProgram;