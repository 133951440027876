import { API_URL } from '../../constant/app-constants';
import { toast } from 'react-toastify';
import axios from 'axios'
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { CreateDirFailure, CreateDirRequest, CreateDirSuccess, DownloadFilesAndDirFailure, DownloadFilesAndDirRequest, DownloadFilesAndDirSuccess, GetFilesAndDirFailure, GetFilesAndDirRequest, GetFilesAndDirSuccess, RemoveFilesAndDirFailure, RemoveFilesAndDirRequest, RemoveFilesAndDirSuccess, StorageSpaceSuccess, removeDownloadNotification, setDownloadNotification, setProgress } from '../reducers/upload.reducers';
import { convertToBytes } from '../../Utils/pipes';
import { generateZipName } from '../../Utils/helpers';

/**
 * Saves file information to the database.
 *
 * @param {string} userId - The ID of the user.
 * @param {string} filePath - The path of the file.
 * @param {string} fileName - The name of the file.
 * @param {string} fileType - The type of the file.
 * @param {number} fileSize - The size of the file.
 * @returns {function} Dispatch function to handle saving file information.
 */
export const saveFileToDB = (userId, filePath, fileName, fileType, fileSize) => async (dispatch) => {
    try {
        const token = localStorage.getItem("token");

        const { data } = await axios.post(API_URL + '/api/v1/files/createFolder',
            { userId, filePath, fileName, fileType, fileSize },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            },
        );

    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error('Something went wrong!');
        }
    }
}

/**
 * Saves small file information to the database.
 *
 * @param {string} userId - The ID of the user.
 * @param {string} fileName - The name of the file alosng with path to uploaded in backblaze.
 * @param {number} fileSize - The size of the file.
 * @param {string} fileId - The ID of the file.
 * @returns {function} Dispatch function to handle saving small file information.
 */
export const saveSmallFileToDB = (userId, fileName, fileSize, fileId) => async (dispatch) => {
    try {
        const token = localStorage.getItem("token");

        const { data } = await axios.post(API_URL + '/api/v1/uploads/saveSmallFile',
            { userId, fileName, fileSize, fileId },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            },
        );

    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error('Something went wrong!');
        }
    }
}

/**
 * Retrieves files and folders.
 *
 * @param {string} filePath - The path of the folder to retrieve files from.
 * @returns {function} Dispatch function to handle fetching files and folders.
 */
export const getFilesAndFolders = (filePath) => async (dispatch) => {
    try {
        dispatch(GetFilesAndDirRequest())
        const token = localStorage.getItem("token");

        const { data } = await axios.post(API_URL + '/api/v1/files/getFolder',
            { filePath },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            },
        );

        dispatch(GetFilesAndDirSuccess(data.data))
        dispatch(userSpace())

    } catch (error) {
        dispatch(GetFilesAndDirFailure())
        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error('File uploading Failed!');
        }
    }
}

/**
 * Removes files and folders.
 *
 * @param {string} filePath - The path of the folder to remove files from.
 * @param {array} selectedFilesAndFolders - The list of selected files and folders to remove.
 * @returns {function} Dispatch function to handle removing files and folders.
 */
export const removeFilesAndFolders = (filePath, selectedFilesAndFolders) => async (dispatch) => {
    try {
        dispatch(RemoveFilesAndDirRequest())
        const token = localStorage.getItem("token");

        const { data } = await axios.post(API_URL + '/api/v1/files/deleteFolder',
            { absolutePath: filePath, folderFiles: selectedFilesAndFolders },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            },
        );

        dispatch(RemoveFilesAndDirSuccess(data.data))
        dispatch(userSpace())

    } catch (error) {
        console.log(error)
        dispatch(RemoveFilesAndDirFailure())
        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error('Something went Wrong!');
        }
    }
}

/**
 * Creates a new folder.
 *
 * @param {string} filePath - The path where the folder will be created.
 * @param {string} FolderName - The name of the folder to create.
 * @returns {function} Dispatch function to handle creating the folder.
 */
export const createFolder = (filePath, FolderName) => async (dispatch) => {
    try {
        dispatch(CreateDirRequest())
        const token = localStorage.getItem("token");
        const params = {
            filePath: filePath,
            fileType: 'dir',
            fileName: FolderName
        }

        const { data } = await axios.post(API_URL + '/api/v1/files/createFolder',
            params,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            },
        );

        dispatch(CreateDirSuccess(data.data))

    } catch (error) {
        console.log(error)
        dispatch(CreateDirFailure())
        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error('Something went Wrong!');
        }
    }
}

/**
 * Downloads files and folders.
 *
 * @param {string} filePath - The path of the folder to download files from.
 * @param {array} selectedFilesAndFolders - The list of selected files and folders to download.
 * @param {number} index - The index of the current download notification.
 * @returns {function} Dispatch function to handle downloading files and folders.
 */
export const downloadFilesAndFolder = (filePath, selectedFilesAndFolders, index) => async (dispatch) => {
    try {
        dispatch(DownloadFilesAndDirRequest());
        const token = localStorage.getItem("token");
        const files = selectedFilesAndFolders.map((item) => ({
            filePath: filePath,
            fileName: item.fileName,
            fileType: item.fileType,
            fileId: item.fileId,
        }));
        const params = { files };

        const { data } = await axios.post(
            API_URL + '/api/v1/files/downloadFile',
            params,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            }
        );

        const downloadUrls = data.data;
        const totalSize = selectedFilesAndFolders.reduce((acc, file) => acc + convertToBytes(file.fileSize), 0);
        // console.log(totalSize)
        let downloadedSize = 0;
        dispatch(DownloadFilesAndDirSuccess());
        dispatch(setDownloadNotification(true));
        if (downloadUrls.length === 1) {
            await downloadFile(downloadUrls[0], dispatch, (bytes) => {
                downloadedSize += bytes;
                const overallProgress = (downloadedSize / totalSize) * 100;
                dispatch(setProgress({ index: index, progress: overallProgress < 100 ? Math.ceil(overallProgress) : 100 }));
            });
            dispatch(removeDownloadNotification(index));

        } else {
            const zip = new JSZip();

            if (window.hasOwnProperty('Promise.allSettled')) {
                await Promise.allSettled(
                    downloadUrls.map((url) => addFileToZip(url, zip, dispatch, totalSize, (bytes) => {
                        // console.log(bytes, downloadedSize, totalSize, 'here')
                        downloadedSize += bytes;
                        const overallProgress = (downloadedSize / totalSize) * 100;
                        dispatch(setProgress({ index: index, progress: overallProgress < 100 ? Math.ceil(overallProgress) : 100 }));
                    }))
                );
            } else {
                for (const url of downloadUrls) {
                    await addFileToZip(url, zip, dispatch, totalSize, (bytes) => {
                        downloadedSize += bytes;
                        // console.log(bytes, downloadedSize, totalSize, 'here')
                        const overallProgress = (downloadedSize / totalSize) * 100;
                        dispatch(setProgress({ index: index, progress: overallProgress < 100 ? Math.ceil(overallProgress) : 100 }));
                    });
                }
            }

            zip.generateAsync({ type: 'blob' }).then((content) => {
                const folderName = generateZipName()
                saveAs(content, folderName);
            });

            dispatch(removeDownloadNotification(index));
        }

    } catch (error) {
        console.log(error);
        dispatch(removeDownloadNotification(index));
        dispatch(DownloadFilesAndDirFailure());
        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error('Something went wrong!');
        }
    }
};

export const userSpace = () => async (dispatch) => {
    try {
        const token = localStorage.getItem("token");
        const { data } = await axios.get(API_URL + '/api/v1/files/getUserSpace',
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            },
        );
        dispatch(StorageSpaceSuccess(data.data))
    } catch (error) {
        console.error('Error checking file:', error);
        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
        } else {
            // toast.error('Something went wrong!');
        }
    }
};

/**
 * Downloads a file.
 *
 * @param {object} item - The item containing the download URL and file details.
 * @param {function} dispatch - The dispatch function.
 * @param {function} onProgress - The progress callback function.
 * @returns {Promise} A promise that resolves when the file is downloaded.
 */
const downloadFile = async (item, dispatch, onProgress) => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', item.downloadUrl, true);
        xhr.responseType = 'blob';

        xhr.onprogress = (event) => {
            if (event.lengthComputable) {
                onProgress(event.loaded - (xhr.lastLoaded || 0));
                xhr.lastLoaded = event.loaded;
                const percentComplete = (event.loaded / event.total) * 100;
                // console.log(`Download progress: ${percentComplete}%`);
            }
        };

        xhr.onload = () => {
            if (xhr.status === 200) {
                const blob = xhr.response;
                const fileName = item.fileName;

                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.setAttribute('download', fileName);
                link.style.display = 'none';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                resolve();
            } else {
                reject(new Error('Failed to download file'));
            }
        };

        xhr.onerror = () => {
            reject(new Error('Network error occurred while downloading file'));
        };

        xhr.send();
    });
};

/**
 * Adds a file to a zip archive.
 *
 * @param {object} item - The item containing the download URL and file details.
 * @param {object} zip - The JSZip instance.
 * @param {function} dispatch - The dispatch function.
 * @param {number} totalSize - The total size of all files being downloaded.
 * @param {function} onProgress - The progress callback function.
 * @returns {Promise} A promise that resolves when the file is added to the zip archive.
 */
const addFileToZip = async (item, zip, dispatch, totalSize, onProgress) => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', item.downloadUrl, true);
        xhr.responseType = 'blob';

        xhr.onprogress = (event) => {
            if (event.lengthComputable) {
                onProgress(event.loaded - (xhr.lastLoaded || 0));
                xhr.lastLoaded = event.loaded;
                const percentComplete = (event.loaded / event.total) * 100;
                // console.log(`Download progress: ${percentComplete}%`);
            }
        };

        xhr.onload = () => {
            if (xhr.status === 200) {
                const blob = xhr.response;
                const fileName = item.fileName;
                zip.file(fileName, blob);
                resolve();
            } else {
                reject(new Error('Failed to download file'));
            }
        };

        xhr.onerror = () => {
            reject(new Error('Network error occurred while downloading file'));
        };

        xhr.send();
    });
};

/**
 * Checks if a file exists in the specified folder.
 *
 * @param {object} file - The file object containing file details.
 * @param {string} folderPath - The path of the folder to check the file in.
 * @returns {Promise<object>} A promise that resolves with the file existence and storage availability status.
 */
export const checkFileExists = async (file, folderPath) => {
    try {
        const token = localStorage.getItem("token");
        const { data } = await axios.post(API_URL + '/api/v1/files/isPathExist',
            { fileName: file.name, folderPath: folderPath, path: file.path, fileSize: file.size },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            },
        );
        return { file: file, isFileExist: data.data.isFileExist, isStorageAvailable: data.data.isStorageAvailable };
    } catch (error) {
        console.error('Error checking file:', error);
        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error('Something went wrong!');
        }
    }
};

